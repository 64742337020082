// import React, { useState, useEffect } from "react";
// import { useParams } from "react-router-dom";
// import Chart from "react-apexcharts";
// import { RiContrastDropLine, RiTestTubeLine, RiHeart3Fill, RiDashboard3Line } from '../../assets/icons/vander';

// export default function Charts() {
//     const id = localStorage.getItem("userId");
//     const [data, setData] = useState({
//     water: 93,
//     hemoglobin: [10, 25, 30, 54, 45, 39, 15],
//     heartbeat: [15, 20, 10, 45, 20, 10, 5],
//     sugarLevels: 90
//   });
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(null);
//   const token = localStorage.getItem('accessToken'); // Retrieve the token from local storage

//   const fetchData = async () => {
//     try {
//       setLoading(true);
//       const response = await fetch(`${process.env.REACT_APP_API_URL}/health-metrics/list/?patient_id=${id}`, {
//         method: 'GET',
//         headers: {
//           'Content-Type': 'application/json',
//           'Authorization': `Bearer ${token}` // Include the token in the Authorization header
//         }
//       });

//       if (!response.ok) {
//         throw new Error("Network response was not ok");
//       }

//       const result = await response.json();
//       console.log(result); // Log the result to see the structure
//       setData(result);
//     } catch (error) {
//       console.error("Error fetching data:", error);
//       setError(error.message);
//     } finally {
//       setLoading(false); // Hide loader
//     }
//   };

//   useEffect(() => {
//     if (id) {
//       fetchData();
//     }
//   }, [id, token]);
 
//     let options = {
//         series: [93],
//         chart: {
//             height: 220,
//             type: 'radialBar',
//         },
//         colors: ['#396cf0'],
//         plotOptions: {
//             radialBar: {
//                 track: {
//                   background: '#b9c1d4',
//                   opacity: 0.5,            
//                 },
//                 hollow: {
//                     size: '70%',
//                 },
//                 dataLabels: {
//                     name: {
//                       show: false,
//                     },
//                     value: {
//                       fontSize: '20px',
//                       offsetY: 10,
//                     },
//                 },
//             },
//         },
//         stroke: {
//             lineCap: 'round',
//         },
//     };
//     let options2 = {
//         series: [{
//             data: [10, 25, 30, 54, 45, 39, 15]
//         }],
//         chart: {
//             type: 'area',
//             height: 90,
//             width: '100%',
//             sparkline: {
//                 enabled: true
//             }
//         },
//         stroke: {
//             curve: 'smooth',
//             width: 3,
//         },
//         colors: ['#53c797'],
//         fill: {
//             type: 'gradient',
//             gradient: {
//                 shadeIntensity: 1,
//                 inverseColors: false,
//                 opacityFrom: 0.45,
//                 opacityTo: 0.05,
//                 stops: [20, 100, 100, 100]
//             },
//         },
//         tooltip: {
//             fixed: {
//                 enabled: false
//             },
//             x: {
//                 show: false
//             },
//             y: {
//                 title: {
//                     formatter: function (seriesName) {
//                         return ''
//                     }
//                 }
//             },
//             marker: {
//                 show: false
//             }
//         }
//     };
//     let options3 = {
//         series: [{
//             data: [15, 20, 10, 45, 20, 10, 5]
//         }],
//         chart: {
//             type: 'area',
//             height: 90,
//             width: '100%',
//             sparkline: {
//                 enabled: true
//             }
//         },
//         stroke: {
//             curve: 'smooth',
//             width: 3,
//         },
//         colors: ['#f0735a'],
//         fill: {
//             type: 'gradient',
//             gradient: {
//                 shadeIntensity: 1,
//                 inverseColors: false,
//                 opacityFrom: 0.45,
//                 opacityTo: 0.05,
//                 stops: [20, 100, 100, 100]
//             },
//         },
//         tooltip: {
//             fixed: {
//                 enabled: false
//             },
//             x: {
//                 show: false
//             },
//             y: {
//                 title: {
//                     formatter: function (seriesName) {
//                         return ''
//                     }
//                 }
//             },
//             marker: {
//                 show: false
//             }
//         }
//     };
//     let options4 = {
//         series: [90],
//         chart: {
//             height: 220,
//             type: 'radialBar',
//         },
//         colors: ['#f1b561'],
//         plotOptions: {
//             radialBar: {
//                 track: {
//                 background: '#b9c1d4',
//                 opacity: 0.5,            
//                 },
//                 hollow: {
//                     size: '70%',
//                 },
//                 dataLabels: {
//                     name: {
//                     show: false,
//                     },
//                     value: {
//                     fontSize: '20px',
//                     offsetY: 10,
//                     },
//                 },
//             },
//         },
//         stroke: {
//             lineCap: 'round',
//         },
//     };
    
//     return(
//         <>
//         <div className="col-xl-3 col-lg-6 col-12">
//             <div className="row">
//                 <div className="col-12 mt-4 pt-2">
//                     <div className="card border-0 rounded shadow">
//                         <div className="d-flex justify-content-between px-4 pt-4">
//                             <h6 className="align-items-center mb-0">Water <span className="badge rounded-pill bg-soft-primary ms-1">93%</span></h6>
//                             <RiContrastDropLine className="text-primary h5"/>
//                         </div>
//                         <Chart
//                             options={options}
//                             series={options.series}
//                             type="radialBar"
//                             width='100%'
//                             height={220}
//                             />
//                     </div>
//                 </div>

//                 <div className="col-12 mt-4 pt-2">
//                     <div className="card border-0 rounded shadow p-4">
//                         <div className="d-flex justify-content-between mb-3">
//                             <h6 className="align-items-center mb-0">Hemoglobin <span className="badge rounded-pill bg-soft-success ms-1">19.5 g/dL</span></h6>
//                             <RiTestTubeLine className="text-success h5"/>
//                         </div>
//                         <Chart
//                             options={options2}
//                             series={options2.series}
//                             type="area"
//                             width='100%'
//                             height={90}
//                             />
//                     </div>
//                 </div>
//             </div>
//         </div>

//         <div className="col-xl-3 col-lg-6 col-12">
//             <div className="row">
//                 <div className="col-12 mt-4 pt-2">
//                     <div className="card border-0 rounded shadow p-4">
//                         <div className="d-flex justify-content-between mb-3">
//                             <h6 className="align-items-center mb-0">Heartbeat <span className="badge rounded-pill bg-soft-danger ms-1">80 bpm</span></h6>
//                             <RiHeart3Fill className="text-danger h5"/>
//                         </div>
//                         <Chart
//                             options={options3}
//                             series={options3.series}
//                             type="area"
//                             width='100%'
//                             height={90}
//                             />
//                     </div>
//                 </div>

//                 <div className="col-12 mt-4 pt-2">
//                     <div className="card border-0 rounded shadow">
//                         <div className="d-flex justify-content-between px-4 pt-4">
//                             <h6 className="align-items-center mb-0">Sugar levels <span className="badge rounded-pill bg-soft-warning ms-1">90 mg/dL</span></h6>
//                             <RiDashboard3Line className="text-warning h5"/>
//                         </div>
//                         <Chart
//                             options={options4}
//                             series={options4.series}
//                             type="radialBar"
//                             width='100%'
//                             height={220}
//                             />
//                     </div>
//                 </div>
//             </div>
//         </div>
//         </>
//     )
// }




// import React, { useState, useEffect } from "react";
// import { useParams } from "react-router-dom";
// import Chart from "react-apexcharts";
// import { RiContrastDropLine, RiTestTubeLine, RiHeart3Fill, RiDashboard3Line } from '../../assets/icons/vander';

// export default function Charts() {
//     const id = localStorage.getItem("userId");
//     const [data, setData] = useState({
//         weight: [0],
//         hemoglobin: [0],
//         heartbeat: [0],
//         sugarLevels: [0]
//     });
//     const [loading, setLoading] = useState(false);
//     const [error, setError] = useState(null);
//     const token = localStorage.getItem('accessToken');

//     const fetchData = async () => {
//         try {
//             setLoading(true);
//             const response = await fetch(`${process.env.REACT_APP_API_URL}/health-metrics/list/?patient_id=${id}`, {
//                 method: 'GET',
//                 headers: {
//                     'Content-Type': 'application/json',
//                     'Authorization': `Bearer ${token}`
//                 }
//             });

//             if (!response.ok) {
//                 throw new Error("Network response was not ok");
//             }

//             const result = await response.json();
//             console.log(result); // Log the result to see the structure

//             // Transform data
//             const weights = result.map(item => item.metrics.weight);
//             const hemoglobins = result.map(item => item.metrics.hemoglobin);
//             const heartbeats = result.map(item => item.metrics.heart_rate);
//             const sugarLevels = result.map(item => item.metrics.sugar_level);

//             setData({
//                 weight: weights,
//                 hemoglobin: hemoglobins,
//                 heartbeat: heartbeats,
//                 sugarLevels: sugarLevels
//             });

//         } catch (error) {
//             console.error("Error fetching data:", error);
//             setError(error.message);
//         } finally {
//             setLoading(false);
//         }
//     };

//     useEffect(() => {
//         if (id) {
//             fetchData();
//         }
//     }, [id, token]);
//     let options1 = {
//         series: [{
//             data: data.weight.map((weight, index) => ({ x: `Record ${index + 1}`, y: weight }))
//         }],
//         chart: {
//             type: 'bar',
//             height: 220,
//         },
//         colors: ['#396cf0'],
//         plotOptions: {
//             bar: {
//                 horizontal: false,
//                 endingShape: 'rounded',
//             },
//         },
//         dataLabels: {
//             enabled: false
//         },
//         stroke: {
//             show: true,
//             colors: ['#396cf0'],
//             width: 2
//         },
//         xaxis: {
//             categories: data.weight.map((_, i) => `Record ${i + 1}`),
//         },
//         yaxis: {
//             title: {
//                 text: 'Weight (kg)'
//             }
//         },
//         tooltip: {
//             y: {
//                 formatter: function (val) {
//                     return `Weight: ${val} kg`;
//                 }
//             },
//             x: {
//                 formatter: function (val, { dataPointIndex }) {
//                     // Check if date_recorded exists and has the index
//                     return data.date_recorded && data.date_recorded[dataPointIndex]
//                         ? `Date: ${new Date(data.date_recorded[dataPointIndex]).toLocaleDateString()}`
//                         : 'Date: N/A';
//                 }
//             }
//         }
//     };
    
//     let options2 = {
//         series: [{
//             data: data.hemoglobin.map((hemoglobin, index) => ({ x: `Record ${index + 1}`, y: hemoglobin }))
//         }],
//         chart: {
//             type: 'bar',
//             height: 90,
//             width: '100%',
//             sparkline: {
//                 enabled: true
//             }
//         },
//         colors: ['#53c797'],
//         plotOptions: {
//             bar: {
//                 horizontal: false,
//                 endingShape: 'rounded',
//             },
//         },
//         dataLabels: {
//             enabled: false
//         },
//         stroke: {
//             show: true,
//             colors: ['#53c797'],
//             width: 2
//         },
//         xaxis: {
//             categories: data.hemoglobin.map((_, i) => `Record ${i + 1}`),
//         },
//         yaxis: {
//             title: {
//                 text: 'Hemoglobin (g/dL)'
//             }
//         },
//         tooltip: {
//             y: {
//                 formatter: function (val) {
//                     return `Hemoglobin: ${val} g/dL`;
//                 }
//             },
//             x: {
//                 formatter: function (val, { dataPointIndex }) {
//                     return data.date_recorded && data.date_recorded[dataPointIndex]
//                         ? `Date: ${new Date(data.date_recorded[dataPointIndex]).toLocaleDateString()}`
//                         : 'Date: N/A';
//                 }
//             }
//         }
//     };
    
//     let options3 = {
//         series: [{
//             data: data.heartbeat.map((heartbeat, index) => ({ x: `Record ${index + 1}`, y: heartbeat }))
//         }],
//         chart: {
//             type: 'bar',
//             height: 90,
//             width: '100%',
//             sparkline: {
//                 enabled: true
//             }
//         },
//         colors: ['#f0735a'],
//         plotOptions: {
//             bar: {
//                 horizontal: false,
//                 endingShape: 'rounded',
//             },
//         },
//         dataLabels: {
//             enabled: false
//         },
//         stroke: {
//             show: true,
//             colors: ['#f0735a'],
//             width: 2
//         },
//         xaxis: {
//             categories: data.heartbeat.map((_, i) => `Record ${i + 1}`),
//         },
//         yaxis: {
//             title: {
//                 text: 'Heartbeat (bpm)'
//             }
//         },
//         tooltip: {
//             y: {
//                 formatter: function (val) {
//                     return `Heartbeat: ${val} bpm`;
//                 }
//             },
//             x: {
//                 formatter: function (val, { dataPointIndex }) {
//                     return data.date_recorded && data.date_recorded[dataPointIndex]
//                         ? `Date: ${new Date(data.date_recorded[dataPointIndex]).toLocaleDateString()}`
//                         : 'Date: N/A';
//                 }
//             }
//         }
//     };
    
//     let options4 = {
//         series: [{
//             data: data.sugarLevels.map((sugarLevel, index) => ({ x: `Record ${index + 1}`, y: sugarLevel }))
//         }],
//         chart: {
//             type: 'bar',
//             height: 220,
//         },
//         colors: ['#f1b561'],
//         plotOptions: {
//             bar: {
//                 horizontal: false,
//                 endingShape: 'rounded',
//             },
//         },
//         dataLabels: {
//             enabled: false
//         },
//         stroke: {
//             show: true,
//             colors: ['#f1b561'],
//             width: 2
//         },
//         xaxis: {
//             categories: data.sugarLevels.map((_, i) => `Record ${i + 1}`),
//         },
//         yaxis: {
//             title: {
//                 text: 'Sugar Levels (mg/dL)'
//             }
//         },
//         tooltip: {
//             y: {
//                 formatter: function (val) {
//                     return `Sugar Level: ${val} mg/dL`;
//                 }
//             },
//             x: {
//                 formatter: function (val, { dataPointIndex }) {
//                     return data.date_recorded && data.date_recorded[dataPointIndex]
//                         ? `Date: ${new Date(data.date_recorded[dataPointIndex]).toLocaleDateString()}`
//                         : 'Date: N/A';
//                 }
//             }
//         }
//     };
    

//     // let options1 = {
//     //     series: [{
//     //         data: data.weight
//     //     }],
//     //     chart: {
//     //         type: 'bar',
//     //         height: 220,
//     //     },
//     //     colors: ['#396cf0'],
//     //     plotOptions: {
//     //         bar: {
//     //             horizontal: false,
//     //             endingShape: 'rounded',
//     //         },
//     //     },
//     //     dataLabels: {
//     //         enabled: false
//     //     },
//     //     stroke: {
//     //         show: true,
//     //         colors: ['#396cf0'],
//     //         width: 2
//     //     },
//     //     xaxis: {
//     //         categories: data.weight.map((_, i) => `Record ${i + 1}`),
//     //     },
//     //     yaxis: {
//     //         title: {
//     //             text: 'Weight (kg)'
//     //         }
//     //     }
//     // };

//     // let options2 = {
//     //     series: [{
//     //         data: data.hemoglobin
//     //     }],
//     //     chart: {
//     //         type: 'bar',
//     //         height: 90,
//     //         width: '100%',
//     //         sparkline: {
//     //             enabled: true
//     //         }
//     //     },
//     //     colors: ['#53c797'],
//     //     plotOptions: {
//     //         bar: {
//     //             horizontal: false,
//     //             endingShape: 'rounded',
//     //         },
//     //     },
//     //     dataLabels: {
//     //         enabled: false
//     //     },
//     //     stroke: {
//     //         show: true,
//     //         colors: ['#53c797'],
//     //         width: 2
//     //     },
//     //     xaxis: {
//     //         categories: data.hemoglobin.map((_, i) => `Record ${i + 1}`),
//     //     },
//     //     yaxis: {
//     //         title: {
//     //             text: 'Hemoglobin (g/dL)'
//     //         }
//     //     }
//     // };

//     // let options3 = {
//     //     series: [{
//     //         data: data.heartbeat
//     //     }],
//     //     chart: {
//     //         type: 'bar',
//     //         height: 90,
//     //         width: '100%',
//     //         sparkline: {
//     //             enabled: true
//     //         }
//     //     },
//     //     colors: ['#f0735a'],
//     //     plotOptions: {
//     //         bar: {
//     //             horizontal: false,
//     //             endingShape: 'rounded',
//     //         },
//     //     },
//     //     dataLabels: {
//     //         enabled: false
//     //     },
//     //     stroke: {
//     //         show: true,
//     //         colors: ['#f0735a'],
//     //         width: 2
//     //     },
//     //     xaxis: {
//     //         categories: data.heartbeat.map((_, i) => `Record ${i + 1}`),
//     //     },
//     //     yaxis: {
//     //         title: {
//     //             text: 'Heartbeat (bpm)'
//     //         }
//     //     }
//     // };

//     // let options4 = {
//     //     series: [{
//     //         data: data.sugarLevels
//     //     }],
//     //     chart: {
//     //         type: 'bar',
//     //         height: 220,
//     //     },
//     //     colors: ['#f1b561'],
//     //     plotOptions: {
//     //         bar: {
//     //             horizontal: false,
//     //             endingShape: 'rounded',
//     //         },
//     //     },
//     //     dataLabels: {
//     //         enabled: false
//     //     },
//     //     stroke: {
//     //         show: true,
//     //         colors: ['#f1b561'],
//     //         width: 2
//     //     },
//     //     xaxis: {
//     //         categories: data.sugarLevels.map((_, i) => `Record ${i + 1}`),
//     //     },
//     //     yaxis: {
//     //         title: {
//     //             text: 'Sugar Levels (mg/dL)'
//     //         }
//     //     }
//     // };

//     return (
//         <>
//             <div className="col-xl-3 col-lg-6 col-12">
//                 <div className="row">
//                     <div className="col-12 mt-4 pt-2">
//                         <div className="card border-0 rounded shadow">
//                             <div className="d-flex justify-content-between px-4 pt-4">
//                                 <h6 className="align-items-center mb-0">Weight <span className="badge rounded-pill bg-soft-primary ms-1">{data.weight.length > 0 ? `${data.weight[data.weight.length - 1]} kg` : 'N/A'}</span></h6>
//                                 <RiContrastDropLine className="text-primary h5"/>
//                             </div>
//                             <Chart
//                                 options={options1}
//                                 series={options1.series}
//                                 type="bar"
//                                 width='100%'
//                                 height={220}
//                             />
//                         </div>
//                     </div>

//                     <div className="col-12 mt-4 pt-2">
//                         <div className="card border-0 rounded shadow p-4">
//                             <div className="d-flex justify-content-between mb-3">
//                                 <h6 className="align-items-center mb-0">Hemoglobin <span className="badge rounded-pill bg-soft-success ms-1">{data.hemoglobin.length > 0 ? `${data.hemoglobin[data.hemoglobin.length - 1]} g/dL` : 'N/A'}</span></h6>
//                                 <RiTestTubeLine className="text-success h5"/>
//                             </div>
//                             <Chart
//                                 options={options2}
//                                 series={options2.series}
//                                 type="bar"
//                                 width='100%'
//                                 height={90}
//                             />
//                         </div>
//                     </div>
//                 </div>
//             </div>

//             <div className="col-xl-3 col-lg-6 col-12">
//                 <div className="row">
//                     <div className="col-12 mt-4 pt-2">
//                         <div className="card border-0 rounded shadow p-4">
//                             <div className="d-flex justify-content-between mb-3">
//                                 <h6 className="align-items-center mb-0">Heartbeat <span className="badge rounded-pill bg-soft-danger ms-1">{data.heartbeat.length > 0 ? `${data.heartbeat[data.heartbeat.length - 1]} bpm` : 'N/A'}</span></h6>
//                                 <RiHeart3Fill className="text-danger h5"/>
//                             </div>
//                             <Chart
//                                 options={options3}
//                                 series={options3.series}
//                                 type="bar"
//                                 width='100%'
//                                 height={90}
//                             />
//                         </div>
//                     </div>

//                     <div className="col-12 mt-4 pt-2">
//                         <div className="card border-0 rounded shadow">
//                             <div className="d-flex justify-content-between px-4 pt-4">
//                                 <h6 className="align-items-center mb-0">Sugar Levels <span className="badge rounded-pill bg-soft-warning ms-1">{data.sugarLevels.length > 0 ? `${data.sugarLevels[data.sugarLevels.length - 1]} mg/dL` : 'N/A'}</span></h6>
//                                 <RiDashboard3Line className="text-warning h5"/>
//                             </div>
//                             <Chart
//                                 options={options4}
//                                 series={options4.series}
//                                 type="bar"
//                                 width='100%'
//                                 height={220}
//                             />
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </>
//     )
// }





import React, { useState, useEffect } from "react";
import { RiContrastDropLine, RiTestTubeLine, RiHeart3Fill, RiDashboard3Line } from '../../assets/icons/vander';

export default function Charts() {
    const id = localStorage.getItem("userId");
    const [data, setData] = useState({
        latestMetrics: {
            weight: 0,
            hemoglobin: 0,
            heartbeat: 0,
            sugarLevels: 0
        },
        percentageDifference: {
            weight: 0,
            hemoglobin: 0,
            heartbeat: 0,
            sugarLevels: 0
        }
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const token = localStorage.getItem('accessToken');
    const fetchData = async () => {
        try {
            setLoading(true);
            const response = await fetch(`${process.env.REACT_APP_API_URL}/health-metrics/list/?patient_id=${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
    
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
    
            const result = await response.json();
            console.log(result); // Log the result to see the structure
    
            // Get the most recent metrics data
            const latestRecord = result[0];
            if (latestRecord) {
                // Extract the latest metrics and percentage differences
                const latestMetrics = latestRecord.metrics || {};
                const percentageDifference = latestRecord.percentage_difference || {};
    
                // Convert metrics_with_lookup to a more convenient object if needed
                const metricsWithLookup = latestRecord.metrics_with_lookup || [];
                const metrics = metricsWithLookup.reduce((acc, metric) => {
                    acc[metric.key] = {
                        value: metric.value,
                        unit: metric.unit
                    };
                    return acc;
                }, {});
    
                setData({
                    latestMetrics: metrics,
                    percentageDifference: percentageDifference
                });
            }
    
        } catch (error) {
            console.error("Error fetching data:", error);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };
    
    // const fetchData = async () => {
    //     try {
    //         setLoading(true);
    //         const response = await fetch(`${process.env.REACT_APP_API_URL}/health-metrics/list/?patient_id=${id}`, {
    //             method: 'GET',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 'Authorization': `Bearer ${token}`
    //             }
    //         });

    //         if (!response.ok) {
    //             throw new Error("Network response was not ok");
    //         }

    //         const result = await response.json();
    //         console.log(result); // Log the result to see the structure

    //         // Extract the latest metrics and percentage differences
    //         const latestMetrics = result[0]?.metrics || {};
    //         const percentageDifference = result[0]?.percentage_difference || {};
    //         setData({
    //             latestMetrics: latestMetrics,
    //             percentageDifference: percentageDifference
    //         });

    //     } catch (error) {
    //         console.error("Error fetching data:", error);
    //         setError(error.message);
    //     } finally {
    //         setLoading(false);
    //     }
    // };

    useEffect(() => {
        if (id) {
            fetchData();
        }
    }, [id, token]);
    return (
        <>
            <div className="col-xl-3 col-lg-6 col-12">
                <div className="row">
                    <div className="col-12 mt-4 pt-2">
                        <div className="card border-0 rounded shadow p-4">
                            <div className="d-flex justify-content-between mb-3">
                                <h6 className="align-items-center mb-0">
                                    Weight Difference
                                    <span className="badge rounded-pill bg-soft-primary ms-1">
                                        {data.percentageDifference.wt ? `${data.percentageDifference.wt.toFixed(2)}%` : 'N/A'}
                                    </span>
                                </h6>
                                <RiContrastDropLine className="text-primary h5"/>
                            </div>
                            <div className="font-weight-bold mb-2">
                                <b> {data.latestMetrics.wt ? `${data.latestMetrics.wt.value} ${data.latestMetrics.wt.unit}` : 'N/A'} </b>
                            </div>
                        </div>
                    </div>
    
                    <div className="col-12 mt-4 pt-2">
                        <div className="card border-0 rounded shadow p-4">
                            <div className="d-flex justify-content-between mb-3">
                                <h6 className="align-items-center mb-0">
                                    Hemoglobin Difference
                                    <span className="badge rounded-pill bg-soft-success ms-1">
                                        {data.percentageDifference.hb ? `${data.percentageDifference.hb.toFixed(2)}%` : 'N/A'}
                                    </span>
                                </h6>
                                <RiTestTubeLine className="text-success h5"/>
                            </div>
                            <div className="font-weight-bold mb-2">
                                <b> {data.latestMetrics.hb ? `${data.latestMetrics.hb.value} ${data.latestMetrics.hb.unit}` : 'N/A'} </b>
                            </div>
                        </div>
                    </div>
    
                    <div className="col-12 mt-4 pt-2">
                        <div className="card border-0 rounded shadow p-4">
                            <div className="d-flex justify-content-between mb-3">
                                <h6 className="align-items-center mb-0">
                                    Heartbeat Difference
                                    <span className="badge rounded-pill bg-soft-danger ms-1">
                                        {data.percentageDifference.hr ? `${data.percentageDifference.hr.toFixed(2)}%` : 'N/A'}
                                    </span>
                                </h6>
                                <RiHeart3Fill className="text-danger h5"/>
                            </div>
                            <div className="font-weight-bold mb-2">
                                <b>{data.latestMetrics.hr ? `${data.latestMetrics.hr.value} ${data.latestMetrics.hr.unit}` : 'N/A'} </b>
                            </div>
                        </div>
                    </div>
    
                    <div className="col-12 mt-4 pt-2">
                        <div className="card border-0 rounded shadow p-4">
                            <div className="d-flex justify-content-between mb-3">
                                <h6 className="align-items-center mb-0">
                                    Sugar Levels Difference
                                    <span className="badge rounded-pill bg-soft-warning ms-1">
                                        {data.percentageDifference.sg ? `${data.percentageDifference.sg.toFixed(2)}%` : 'N/A'}
                                    </span>
                                </h6>
                                <RiDashboard3Line className="text-warning h5"/>
                            </div>
                            <div className="font-weight-bold mb-2">
                               <b> {data.latestMetrics.sg ? `${data.latestMetrics.sg.value} ${data.latestMetrics.sg.unit}` : 'N/A'} </b>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
    
    // return (
    //     <>
    //         <div className="col-xl-3 col-lg-6 col-12">
    //             <div className="row">
    //                 <div className="col-12 mt-4 pt-2">
    //                     <div className="card border-0 rounded shadow p-4">
    //                         <div className="d-flex justify-content-between mb-3">
    //                             <h6 className="align-items-center mb-0">
    //                                 Weight Difference
    //                                 <span className="badge rounded-pill bg-soft-primary ms-1">
    //                                     {data.percentageDifference.weight ? `${data.percentageDifference.weight.toFixed(2)}%` : 'N/A'}
    //                                 </span>
    //                             </h6>
    //                             <RiContrastDropLine className="text-primary h5"/>
    //                         </div>
    //                         <div className="font-weight-bold mb-2">
    //                             <b> {data.latestMetrics.weight ? `${data.latestMetrics.weight} kg` : 'N/A'} </b>
    //                         </div>
    //                     </div>
    //                 </div>

    //                 <div className="col-12 mt-4 pt-2">
    //                     <div className="card border-0 rounded shadow p-4">
    //                         <div className="d-flex justify-content-between mb-3">
    //                             <h6 className="align-items-center mb-0">
    //                                 Hemoglobin Difference
    //                                 <span className="badge rounded-pill bg-soft-success ms-1">
    //                                     {data.percentageDifference.hemoglobin ? `${data.percentageDifference.hemoglobin.toFixed(2)}%` : 'N/A'}
    //                                 </span>
    //                             </h6>
    //                             <RiTestTubeLine className="text-success h5"/>
    //                         </div>
    //                         <div className="font-weight-bold mb-2">
    //                             <b> {data.latestMetrics.hemoglobin ? `${data.latestMetrics.hemoglobin} g/dL` : 'N/A'} </b>
    //                         </div>
    //                     </div>
    //                 </div>

    //                 <div className="col-12 mt-4 pt-2">
    //                     <div className="card border-0 rounded shadow p-4">
    //                         <div className="d-flex justify-content-between mb-3">
    //                             <h6 className="align-items-center mb-0">
    //                                 Heartbeat Difference
    //                                 <span className="badge rounded-pill bg-soft-danger ms-1">
    //                                     {data.percentageDifference.heart_rate ? `${data.percentageDifference.heart_rate.toFixed(2)}%` : 'N/A'}
    //                                 </span>
    //                             </h6>
    //                             <RiHeart3Fill className="text-danger h5"/>
    //                         </div>
    //                         <div className="font-weight-bold mb-2">
    //                             <b>{data.latestMetrics.heart_rate ? `${data.latestMetrics.heart_rate} bpm` : 'N/A'} </b>
    //                         </div>
    //                     </div>
    //                 </div>

    //                 <div className="col-12 mt-4 pt-2">
    //                     <div className="card border-0 rounded shadow p-4">
    //                         <div className="d-flex justify-content-between mb-3">
    //                             <h6 className="align-items-center mb-0">
    //                                 Sugar Levels Difference
    //                                 <span className="badge rounded-pill bg-soft-warning ms-1">
    //                                     {data.percentageDifference.sugar_level ? `${data.percentageDifference.sugar_level.toFixed(2)}%` : 'N/A'}
    //                                 </span>
    //                             </h6>
    //                             <RiDashboard3Line className="text-warning h5"/>
    //                         </div>
    //                         <div className="font-weight-bold mb-2">
    //                            <b> {data.latestMetrics.sugar_level ? `${data.latestMetrics.sugar_level} mg/dL` : 'N/A'} </b>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </div>
    //     </>
    // );
}
