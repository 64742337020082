import React, { useState } from "react";
// import Modal from "react-bootstrap/Modal";
import { Modal, Table, Button } from "react-bootstrap";
import PrescriptionModal from "../dashboard/PrescriptionModal";
import { FaEye } from "react-icons/fa";
import dayjs from "dayjs";
export default function PrescriptionHistory({
  prescription,
  showPrescriptionHistory,
  setShowPrescriptionHistory,
  downloadPDF,
}) {
  const [showModal, setShowModal] = useState(false);
  const [currentItems, setCurrentItems] = useState([]);
  const truncateText = (text, length) => {
    if (text.length > length) {
      return text.slice(0, length) + "...";
    }
    return text;
  };
  const handleShowModal = (items) => {
    setCurrentItems(items);
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };
  return (
    <div>
      {" "}
      <Modal
        show={showPrescriptionHistory}
        onHide={() => setShowPrescriptionHistory(false)}
        animation={false}
        size="lg"
        centered
        dialogClassName="modal-fullwidth"
      >
        <Modal.Header closeButton>
          <Modal.Title className="h5">Prescription Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {prescription && prescription.length > 0 ? (
            <>
              <div className="mb-3">
                <label className="form-label">Doctor Name</label>
                <p className="form-control">
                  {prescription[0]?.doctor_first_name}{" "}
                  {prescription[0]?.doctor_last_name}
                </p>
              </div>

              <Table size="sm" style={{ marginBottom: 0 }}>
                <thead>
                  <tr>
                    <th>Description</th>
                    <th>Date</th>
                    <th>Details</th>
                  </tr>
                </thead>
                <tbody>
                  {prescription?.map((record) => (
                    <tr key={record.id}>
                      <td>{truncateText(record.description, 90)}</td>
                      <td>{record.date}</td>
                      <td>
                        <Button
                          variant="link"
                          onClick={() => handleShowModal(record.items)}
                        >
                          <FaEye />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              <Button
                className="btn btn-primary mt-3"
                onClick={() => downloadPDF(prescription)}
              >
                Download PDF
              </Button>
            </>
          ) : (
            <p>No prescription details available for this appointment.</p>
          )}
        </Modal.Body>
      </Modal>
      <PrescriptionModal
        show={showModal}
        onHide={handleCloseModal}
        items={currentItems}
      />
    </div>
  );
}
