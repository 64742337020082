// import React, { useEffect, useState } from "react";
// import { patientsData } from "../../data/data";

// import TinySlider from "tiny-slider-react";
// import 'tiny-slider/dist/tiny-slider.css';

// const settings = {
//     container: '.client-review-slider',
//     items: 1,
//     controls: false,
//     mouseDrag: true,
//     loop: true,
//     rewind: true,
//     autoplay: true,
//     autoplayButtonOutput: false,
//     autoplayTimeout: 3000,
//     navPosition: "bottom",
//     speed: 400,
//     gutter: 16,
//   };

//   export default function ReviewOne(){
//     const doctorId = localStorage.getItem("doctorId")
//     const [doctorReview, setDoctorReview] = useState([]);

//     useEffect(() => {
//         // Fetch doctor data from API
//         fetch(`${process.env.REACT_APP_API_URL}/doctors/${doctorId}/ratings/`)
//             .then(response => response.json())
//             .then(data => setDoctorReview(data))
//             .catch(error => console.error('Error fetching doctor data:', error));
//     }, []);
//     return(
//         <div className="client-review-slider">
//             <TinySlider settings={settings}>
//                 {doctorReview.map((item,index) =>{
//                     return(
//                         <div className="tiny-slide" key={index}>
//                             <p className="text-muted fst-italic">{item.user_name}</p>
                            
//                             <div className="d-inline-flex align-items-center">
//                                 {/* <img src={item.image} className="img-fluid avatar avatar-small rounded-circle mx-auto shadow my-3" alt=""/> */}
//                                 <div className="ms-3">
//                                     <ul className="list-unstyled d-block mb-0">
//                                         <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
//                                         <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
//                                         <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
//                                         <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
//                                         <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
//                                     </ul>
//                                     <h6 className="text-primary">{item.name}<small className="text-muted">{item.title}</small></h6>
//                                 </div>
//                             </div>
//                         </div>
//                     )
//                 })}
//             </TinySlider>
//         </div>
//     )
//   }

// import React, { useEffect, useState } from "react";
// import TinySlider from "tiny-slider-react";
// import 'tiny-slider/dist/tiny-slider.css';

// const settings = {
//   container: '.client-review-slider',
//   items: 1,
//   controls: false,
//   mouseDrag: true,
//   loop: true,
//   rewind: true,
//   autoplay: true,
//   autoplayButtonOutput: false,
//   autoplayTimeout: 3000,
//   navPosition: "bottom",
//   speed: 400,
//   gutter: 16,
// };

// export default function ReviewOne() {
//   const doctorId = localStorage.getItem("doctorId");
//   const [doctorReview, setDoctorReview] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     // Fetch doctor data from API
//     fetch(`${process.env.REACT_APP_API_URL}/doctors/${doctorId}/ratings/`)
//       .then(response => {
//         if (!response.ok) {
//           throw new Error('Network response was not ok');
//         }
//         return response.json();
//       })
//       .then(data => {
//         setDoctorReview(data);
//         setLoading(false);
//       })
//       .catch(error => {
//         setError(error);
//         setLoading(false);
//       });
//   }, [doctorId]);

//   if (loading) return <p>Loading reviews...</p>;
//   if (error) return <p>Error loading reviews: {error.message}</p>;

//   return (
//     <div className="client-review-slider">
//       <TinySlider settings={settings}>
//         {doctorReview.map((item, index) => {
//           // Generate star elements based on the rating
//           const fullStars = Array(item.rating).fill(0).map((_, i) => (
//             <li key={i} className="list-inline-item">
//               <i className="mdi mdi-star text-warning"></i>
//             </li>
//           ));
//           const emptyStars = Array(5 - item.rating).fill(0).map((_, i) => (
//             <li key={i} className="list-inline-item">
//               <i className="mdi mdi-star text-muted"></i>
//             </li>
//           ));
//           const stars = [...fullStars, ...emptyStars];

//           return (
//             <div className="tiny-slide" key={item.id}>
//               <p className="text-muted fst-italic">{item.user_name}</p>
//               <div className="d-inline-flex align-items-center">
//                 <div className="ms-3">
//                   <ul className="list-unstyled d-block mb-0">
//                     {stars}
//                   </ul>
//                   <p className="text-primary">{item.review_msg}</p>
//                 </div>
//               </div>
//             </div>
//           );
//         })}
//       </TinySlider>
//     </div>
//   );
// }


import React, { useEffect, useState } from "react";
import TinySlider from "tiny-slider-react";
import 'tiny-slider/dist/tiny-slider.css';

const settings = {
  container: '.client-review-slider',
  items: 1,
  controls: false,
  mouseDrag: true,
  loop: true,
  rewind: true,
  autoplay: true,
  autoplayButtonOutput: false,
  autoplayTimeout: 3000,
  navPosition: "bottom",
  speed: 400,
  gutter: 16,
};

export default function ReviewOne() {
  const doctorId = localStorage.getItem("doctorId");
  const [doctorReview, setDoctorReview] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch doctor data from API
    fetch(`${process.env.REACT_APP_API_URL}/doctors/${doctorId}/ratings/`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setDoctorReview(data);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, [doctorId]);

  if (loading) return <p>Loading reviews...</p>;
  if (error) return <p>Error loading reviews: {error.message}</p>;

  // Inline styles for the container
  const containerStyle = {
    maxHeight: doctorReview.length > 10 ? '300px' : 'auto',
    overflowY: doctorReview.length > 10 ? 'auto' : 'visible',
  };

  return (
    <div className="client-review-slider-container" style={containerStyle}>
      <TinySlider settings={settings}>
        {doctorReview.map((item, index) => {
          // Generate star elements based on the rating
          const fullStars = Array(item.rating).fill(0).map((_, i) => (
            <li key={i} className="list-inline-item">
              <i className="mdi mdi-star text-warning"></i>
            </li>
          ));
          const emptyStars = Array(5 - item.rating).fill(0).map((_, i) => (
            <li key={i} className="list-inline-item">
              <i className="mdi mdi-star text-muted"></i>
            </li>
          ));
          const stars = [...fullStars, ...emptyStars];

          return (
            <div className="tiny-slide" key={item.id}>
              <p className="text-muted fst-italic">{item.user_name}</p>
              <div className="d-inline-flex align-items-center">
                <div className="ms-3">
                  <ul className="list-unstyled d-block mb-0">
                    {stars}
                  </ul>
                  <p className="text-primary">{item.review_msg}</p>
                </div>
              </div>
            </div>
          );
        })}
      </TinySlider>
    </div>
  );
}
