import React from "react";
import { Modal, Table } from "react-bootstrap";

const PrescriptionModal = ({ show, onHide, items }) => {
  return (
    <Modal show={show} onHide={onHide} centered size="sm">
      <Modal.Header closeButton>
        <Modal.Title>Prescription Items</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ height: "500px", overflow: "auto" }}>
          <Table size="sm">
            <thead>
              <tr>
                <th>Medicine Name</th>
                <th>Dosage</th>
                <th>Frequency</th>
                <th>Duration</th>
              </tr>
            </thead>
            <tbody>
              {items.map((item) => (
                <tr key={item.id}>
                  <td>{item.medicine_name}</td>
                  <td>{item.dosage}</td>
                  <td>{item.frequency}</td>
                  <td>{item.duration}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PrescriptionModal;
