import { combineReducers } from "redux";

import DoctorReducer from "../store/doctor/reducer";
import CommonReducer from "./common/reducer";

const rootReducer = () => {
  return combineReducers({
    DoctorReducer,
    CommonReducer,
  });
};

export default rootReducer;
