import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { FiEdit } from "react-icons/fi";
import bg1 from "../assets/images/doctors/profile-bg.jpg";
import dr1 from "../assets/images/doctors/01.jpg";
import axios from "axios";
import { ShowSucess, ShowError } from "../_helper/helper";
import { useSelector, useDispatch } from "react-redux";
import {
  RiAirplayLine,
  RiCalendarCheckLine,
  RiTimerLine,
  RiPagesLine,
  RiMailUnreadLine,
  RiUser2Line,
  RiUserSettingsLine,
  RiEmpathizeLine,
  RiChat1Line,
  RiChatVoiceLine,
  RiLoginCircleLine,
  RiDeviceRecoverLine,
  //   FiEdit,
} from "../assets/icons/vander";
import { doctorDetails } from "../store/doctor/actions";

export default function Sidebar({ colClass }) {
  const doctorId = localStorage.getItem("doctorId");
  const [profileImage, setProfileImage] = useState(null);
  let location = useLocation();
  const [userData, setUserData] = useState(null);
  const baseURL = process.env.REACT_APP_API_URL;
  const dispatch = useDispatch();
  const { doctorDetailsResults } = useSelector((state) => state.DoctorReducer);
  const { image } = doctorDetailsResults;
  const getUserData = () => {
    const userData = localStorage.getItem("userData");
    if (userData) {
      return JSON.parse(userData);
    }

    return null;
  };
  const token = localStorage.getItem("accessToken");
  const handleUpdateClick = async (file) => {
    try {
      const formData = new FormData();
      formData.append("image", file);
      const result = await axios.put(
        `${baseURL}/doctor/update-image/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("result", result);
      const resp = result.data.image;
      if (resp) {
        const doctorid = localStorage.getItem("doctorId");
        dispatch(doctorDetails(doctorid));
        ShowSucess("Profile Updated Successfully");
      } else {
        ShowError("Something Went Wrong!.");
      }
    } catch (err) {
      ShowError(err);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      //   setProfileImage(imageUrl);
      handleUpdateClick(file);
    }
  };

  useEffect(() => {
    setUserData(getUserData());
  }, []);

  return (
    <>
      <div className={colClass}>
        <div className="rounded shadow overflow-hidden sticky-bar">
          <div className="card border-0">
            <img src={bg1} className="img-fluid" alt="" />
          </div>

          <div className="text-center avatar-profile margin-negative mt-n5 position-relative pb-4 border-bottom">
            <img
              src={image || dr1}
              className="rounded-circle shadow-md avatar avatar-md-md"
              alt="Profile"
            />
            <label for="profile_pic">
              <FiEdit
                type="file"
                title="Update Profile"
                style={{ marginTop: "55px", cursor: "pointer" }}
                //   onClick={handleUpdateClick}
              />{" "}
            </label>
            <input
              type="file"
              id="profile_pic"
              name="profile_pic"
              style={{ display: "none" }}
              onChange={handleFileChange}
              accept=".jpg, .jpeg, .png"
            />
            <h5 className="mt-3 mb-1">
              {`${userData?.user?.first_name} ${userData?.user?.last_name}`}
            </h5>
            <p className="text-muted mb-0">Orthopedic</p>
          </div>

          <ul className="list-unstyled sidebar-nav mb-0">
            <li
              className={`${
                location.pathname === "/doctor-dashboard" ? "active" : ""
              } navbar-item mb-2`}
            >
              <Link to="/doctor-dashboard" className="navbar-link">
                <RiAirplayLine className="align-middle navbar-icon" /> Dashboard
              </Link>
            </li>
            <li
              className={`${
                location.pathname === "/doctor-appointments" ? "active" : ""
              } navbar-item mb-2`}
            >
              <Link
                to={`/doctor/appointments/${doctorId}`}
                className="navbar-link"
              >
                <RiCalendarCheckLine className="align-middle navbar-icon" />{" "}
                Appointment
              </Link>
            </li>
            <li
              className={`${
                location.pathname === "/doctor-schedule" ? "active" : ""
              } navbar-item mb-2`}
            >
              <Link to="/doctor-schedule" className="navbar-link">
                <RiTimerLine className="align-middle navbar-icon" /> Schedule
                Timing
              </Link>
            </li>
            <li
              className={`${
                location.pathname === "/Cliniclist" ? "active" : ""
              } navbar-item mb-2`}
            >
              <Link to={`/Cliniclist/${doctorId}`} className="navbar-link">
                <RiUser2Line className="align-middle navbar-icon" /> Clinic List
              </Link>
            </li>
            <li
              className={`${
                location.pathname === "/clinictimetable" ? "active" : ""
              } navbar-item mb-2`}
            >
              <Link to={`/clinictimetable/${doctorId}`} className="navbar-link">
                <RiUser2Line className="align-middle navbar-icon" />
                Clinic Timetable
              </Link>
            </li>
            <li
              className={`${
                location.pathname === "/invoices" ? "active" : ""
              } navbar-item mb-2`}
            >
              <Link to="/invoices" className="navbar-link">
                <RiPagesLine className="align-middle navbar-icon" /> Invoices
              </Link>
            </li>
            <li
              className={`${
                location.pathname === "/doctor-messages" ? "active" : ""
              } navbar-item mb-2`}
            >
              <Link to="/doctor-messages" className="navbar-link">
                <RiMailUnreadLine className="align-middle navbar-icon" />{" "}
                Messages
              </Link>
            </li>
            <li
              className={`${
                location.pathname === "/doctor-profileview" ? "active" : ""
              } navbar-item mb-2`}
            >
              <Link
                to={`/doctor-profileview/${doctorId}`}
                className="navbar-link"
              >
                <RiUser2Line className="align-middle navbar-icon" /> Profile
              </Link>
            </li>

            <li
              className={`${
                location.pathname === "/StaffManagement" ? "active" : ""
              } navbar-item mb-2`}
            >
              <Link to={`/StaffManagement`} className="navbar-link">
                <RiUser2Line className="align-middle navbar-icon" />{" "}
                StaffManagement
              </Link>
            </li>
            <li
              className={`${
                location.pathname === "/doctor-unavailable" ? "active" : ""
              } navbar-item mb-2`}
            >
              <Link
                to={`/doctor-unavailable/${doctorId}`}
                className="navbar-link"
              >
                <RiUser2Line className="align-middle navbar-icon" />{" "}
                Unavailibility
              </Link>
            </li>

            <li
              className={`${
                location.pathname === "/doctor-profile-setting" ? "active" : ""
              } navbar-item mb-2`}
            >
              <Link to="/doctor-profile-setting" className="navbar-link">
                <RiUserSettingsLine className="align-middle navbar-icon" />{" "}
                Profile Settings
              </Link>
            </li>
        
            <li
              className={`${
                location.pathname === "/doctor-profile-setting" ? "active" : ""
              } navbar-item mb-2`}
            >
              <Link to="/doctor-profile-edit" className="navbar-link">
                <RiUserSettingsLine className="align-middle navbar-icon" /> Edit
                Profile Details
              </Link>
            </li>
            <li
              className={`${
                location.pathname === "/patient-list" ? "active" : ""
              } navbar-item mb-2`}
            >
              <Link to="/patient-list" className="navbar-link">
                <RiEmpathizeLine className="align-middle navbar-icon" />{" "}
                Patients
              </Link>
            </li>
            <li
              className={`${
                location.pathname === "/videoconference" ? "active" : ""
              } navbar-item mb-2`}
            >
              <Link to="/videoconference" className="navbar-link">
                <RiEmpathizeLine className="align-middle navbar-icon" />{" "}
                Video Conference
              </Link>
            </li>
            <li
              className={`${
                location.pathname === "/patient-review" ? "active" : ""
              } navbar-item mb-2`}
            >
              <Link to="/patient-review" className="navbar-link">
                <RiChat1Line className="align-middle navbar-icon" /> Patients
                Review
              </Link>
            </li>
            <li
              className={`${
                location.pathname === "/doctor-chat" ? "active" : ""
              } navbar-item mb-2`}
            >
              <Link to="/doctor-chat" className="navbar-link">
                <RiChatVoiceLine className="align-middle navbar-icon" /> Chat
              </Link>
            </li>
            {/* <li className={`${location.pathname === '/login'? 'active' : ''} navbar-item mb-2`}><Link to="/login" className="navbar-link"><RiLoginCircleLine className="align-middle navbar-icon"/> Login</Link></li> */}
            {/* <li className="navbar-item"><Link to="/forgot-password" className="navbar-link"><RiDeviceRecoverLine className="align-middle navbar-icon"/> Forgot Password</Link></li> */}
          </ul>
        </div>
      </div>
    </>
  );
}
