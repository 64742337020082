// import React, { useState } from "react";
// import { Link, useNavigate } from "react-router-dom";
// import axios from "axios";
// import { ToastContainer, toast } from "react-toastify"; 
// import 'react-toastify/dist/ReactToastify.css';

// import bg1 from '../assets/images/bg/bg-lines-one.png';
// import logoDark from '../assets/images/logo-dark.png';

// import { FiHome } from '../assets/icons/vander';
// import { FaSquareFacebook } from 'react-icons/fa6';

// export default function Signup() {
//     // const [username, setUsername] = useState("");
//     const [first_name, setFirstname] = useState("");
//     const [last_name, setLastname] = useState("");
//     const [email, setEmail] = useState("");
//     const [password, setPassword] = useState("");
//     const [gender, setGender] = useState("");
//     const [phone_number, setPhoneNumber] = useState("");
//     const [age, setAge] = useState("");
//     const [blood_type, setBloodGroup] = useState("");
//     const [error, setError] = useState("");
//     const [success, setSuccess] = useState("");
//     const [loading, setLoading] = useState(false);  
//     const navigate = useNavigate();

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         setError("");
//         setSuccess("");
//         setLoading(true);

//         // Map gender to "M" or "F"

//         try {
//             const response = await axios.post(`${process.env.REACT_APP_API_URL}/register/user/`, {
//                 // username,
//                 first_name,
//                 last_name,
//                 email,
//                 password,
//                 gender,
//                 phone_number,
//                 age,
//                 blood_type,
//                 role:"patient",
//             });
//             setSuccess("Registration successful! Please check your email to verify your account.");
//             toast.success("Registration successful! Please check your email to verify your account.");
//             setTimeout(() => {
//                 navigate("/login");
//             }, 2000);
//         } catch (err) {
//             setError("Registration failed. Please try again.");
//             toast.error("Registration failed. Please try again.");
//         } finally {
//             setLoading(false);
//         }
//     };

//     return (
//         <>
//             <div className="back-to-home rounded d-none d-sm-block">
//                 <Link to="/" className="btn btn-icon btn-primary"><FiHome className="icons" /></Link>
//             </div>

//             <section className="bg-home d-flex bg-light align-items-center" style={{ backgroundImage: `url(${bg1})`, backgroundPosition: 'center' }}>
//                 <div className="container">
//                     <div className="row justify-content-center">
//                         <div className="col-lg-8 col-md-10">
//                             {/* <img src={logoDark} height="22" className="mx-auto d-block" alt="" /> */}
//                             <div className="card login-page shadow mt-4 rounded border-0">
//                                 <div className="card-body">
//                                     <h4 className="text-center">Sign Up</h4>
//                                     <form className="login-form mt-4" onSubmit={handleSubmit}>
//                                         <div className="row">
//                                             <div className="col-md-6 mb-3">
//                                                 <label className="form-label">First Name <span className="text-danger">*</span></label>
//                                                 <input type="text" className="form-control" placeholder="First Name" value={first_name} onChange={(e) => setFirstname(e.target.value)} required />
//                                             </div>
//                                             <div className="col-md-6 mb-3">
//                                                 <label className="form-label">Last Name <span className="text-danger">*</span></label>
//                                                 <input type="text" className="form-control" placeholder="Last Name" value={last_name} onChange={(e) => setLastname(e.target.value)} required />
//                                             </div>
//                                             {/* <div className="col-md-6 mb-3">
//                                                 <label className="form-label">User Name <span className="text-danger">*</span></label>
//                                                 <input type="text" className="form-control" placeholder="User Name" value={username} onChange={(e) => setUsername(e.target.value)} required />
//                                             </div> */}
//                                             <div className="col-md-6 mb-3">
//                                                 <label className="form-label">Your Email <span className="text-danger">*</span></label>
//                                                 <input type="email" className="form-control" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} required />
//                                             </div>
//                                             <div className="col-md-6 mb-3">
//                                                 <label className="form-label">Password <span className="text-danger">*</span></label>
//                                                 <input type="password" className="form-control" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} required />
//                                             </div>
//                                             <div className="col-md-6 mb-3">
//                                                 <label className="form-label">Gender <span className="text-danger">*</span></label>
//                                                 <select className="form-control" value={gender} onChange={(e) => setGender(e.target.value)} required>
//                                                     <option value="">Select Gender</option>
//                                                     <option value="M">Male</option>
//                                                     <option value="F">Female</option>
//                                                     {/* <option value="other">Other</option> */}
//                                                 </select>
//                                             </div>
                                            
//                                             <div className="col-md-6 mb-3">
//                                                 <label className="form-label">Phone Number <span className="text-danger">*</span></label>
//                                                 <input type="tel" className="form-control" placeholder="Phone Number" value={phone_number} onChange={(e) => setPhoneNumber(e.target.value)} required />
//                                             </div>
//                                             <div className="col-md-6 mb-3">
//                                                 <label className="form-label">Age <span className="text-danger">*</span></label>
//                                                 <input type="text" className="form-control" placeholder="Age" value={age} onChange={(e) => setAge(e.target.value)} required />
//                                             </div>
//                                             <div className="col-md-6 mb-3">
//                                                 <label className="form-label">Blood Group <span className="text-danger">*</span></label>
//                                                 <select className="form-control" value={blood_type} onChange={(e) => setBloodGroup(e.target.value)} required>
//                                                     <option value="">Select Blood Group</option>
//                                                     <option value="A+">A+</option>
//                                                     <option value="A-">A-</option>
//                                                     <option value="B+">B+</option>
//                                                     <option value="B-">B-</option>
//                                                     <option value="AB+">AB+</option>
//                                                     <option value="AB-">AB-</option>
//                                                     <option value="O+">O+</option>
//                                                     <option value="O-">O-</option>
//                                                 </select>
//                                             </div>
//                                             <div className="col-md-12 mb-3">
//                                                 <div className="d-grid">
//                                                     <button className="btn btn-primary" type="submit" disabled={loading}>
//                                                         {loading ? (
//                                                             <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
//                                                         ) : (
//                                                             'Register'
//                                                         )}
//                                                     </button>
//                                                 </div>
//                                             </div>
//                                             {error && <div className="col-md-12 mb-3"><p className="text-danger text-center">{error}</p></div>}
//                                             {success && <div className="col-md-12 mb-3"><p className="text-success text-center">{success}</p></div>}
//                                             <div className="col-lg-12 mt-2 text-center">
//     <h6 className="text-muted">Or</h6>
// </div>
// <div className="text-center mt-1">
//     <p className="mb-0">
//         <small className="text-dark me-1">Already have an account?</small> 
//         <Link to="/login" className="text-dark fw-bold">Sign in</Link>
//     </p>
// </div>

//                                         </div>
//                                     </form>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </section>
//             <ToastContainer />
//         </>
//     );
// }



import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify"; 
import 'react-toastify/dist/ReactToastify.css';

import bg1 from '../assets/images/bg/bg-lines-one.png';
import logoDark from '../assets/images/logo-dark.png';

import { FiHome } from '../assets/icons/vander';
import { FaSquareFacebook } from 'react-icons/fa6';

export default function Signup() {
    const [first_name, setFirstname] = useState("");
    const [last_name, setLastname] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [gender, setGender] = useState("");
    const [phone_number, setPhoneNumber] = useState("");
    const [age, setAge] = useState("");
    const [blood_type, setBloodGroup] = useState("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [loading, setLoading] = useState(false);
    const [passwordErrors, setPasswordErrors] = useState([]);
    const navigate = useNavigate();

    const validatePassword = (password) => {
        const errors = [];
        if (password.length < 8) errors.push('Password must be at least 8 characters long.');
        if (!/[A-Z]/.test(password)) errors.push('Password must contain at least one uppercase letter.');
        if (!/[0-9]/.test(password)) errors.push('Password must contain at least one number.');
        if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) errors.push('Password must contain at least one special character.');
        return errors;
    };

    const handlePasswordChange = (e) => {
        const value = e.target.value;
        setPassword(value);
        setPasswordErrors(validatePassword(value));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError("");  // Clear any previous errors
        setSuccess("");
        setLoading(true);
    
        if (passwordErrors.length > 0) {
            setError('Please fix the errors in the password fields.');
            toast.error('Please fix the errors in the password fields.');
            setLoading(false);
            return;
        }
    
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/register/user/`, {
                first_name,
                last_name,
                email,
                password,
                gender,
                phone_number,
                age,
                blood_type,
                role: "patient",
            });
            setSuccess("Registration successful! Please check your email to verify your account.");
            toast.success("Registration successful! Please check your email to verify your account.");
            setTimeout(() => {
                navigate("/login");
            }, 2000);
        } catch (err) {
            // Check if the error response exists and handle accordingly
            if (err.response && err.response.data) {
                const errorMessages = Object.values(err.response.data).flat();  // Flatten the array of error messages
                setError(errorMessages.join(' '));  // Join all error messages into a single string
                toast.error(errorMessages.join(' '));  // Display errors using toast
            } else {
                setError("Registration failed. Please try again.");
                toast.error("Registration failed. Please try again.");
            }
        } finally {
            setLoading(false);
        }
    };
    

    return (
        <>
            <div className="back-to-home rounded d-none d-sm-block">
                <Link to="/" className="btn btn-icon btn-primary"><FiHome className="icons" /></Link>
            </div>
    
            <section className="bg-home d-flex bg-light align-items-center" style={{ backgroundImage: `url(${bg1})`, backgroundPosition: 'center' }}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-md-10">
                            <div className="card login-page shadow mt-4 rounded border-0">
                                <div className="card-body">
                                    <h4 className="text-center">Sign Up</h4>
                                    <form className="login-form mt-4" onSubmit={handleSubmit}>
                                        <div className="row">
                                            <div className="col-md-6 mb-3">
                                                <label className="form-label">First Name <span className="text-danger">*</span></label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="First Name"
                                                    value={first_name}
                                                    onChange={(e) => setFirstname(e.target.value)}
                                                    required
                                                />
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label className="form-label">Last Name <span className="text-danger">*</span></label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Last Name"
                                                    value={last_name}
                                                    onChange={(e) => setLastname(e.target.value)}
                                                    required
                                                />
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label className="form-label">Your Email <span className="text-danger">*</span></label>
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    placeholder="Email"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    required
                                                />
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label className="form-label">Password <span className="text-danger">*</span></label>
                                                <input
                                                    type="password"
                                                    className="form-control"
                                                    placeholder="Password"
                                                    value={password}
                                                    onChange={handlePasswordChange}
                                                    required
                                                />
                                                <ul className="mt-2">
                                                    {passwordErrors.map((error, index) => (
                                                        <li key={index} className="text-danger">{error}</li>
                                                    ))}
                                                </ul>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label className="form-label">Gender <span className="text-danger">*</span></label>
                                                <select className="form-control" value={gender} onChange={(e) => setGender(e.target.value)} required>
                                                    <option value="">Select Gender</option>
                                                    <option value="M">Male</option>
                                                    <option value="F">Female</option>
                                                </select>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label className="form-label">Phone Number <span className="text-danger">*</span></label>
                                                <input
                                                    type="tel"
                                                    className="form-control"
                                                    placeholder="Phone Number"
                                                    value={phone_number}
                                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                                    required
                                                />
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label className="form-label">Age <span className="text-danger">*</span></label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Age"
                                                    value={age}
                                                    onChange={(e) => setAge(e.target.value)}
                                                    required
                                                />
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label className="form-label">Blood Group <span className="text-danger">*</span></label>
                                                <select className="form-control" value={blood_type} onChange={(e) => setBloodGroup(e.target.value)} required>
                                                    <option value="">Select Blood Group</option>
                                                    <option value="A+">A+</option>
                                                    <option value="A-">A-</option>
                                                    <option value="B+">B+</option>
                                                    <option value="B-">B-</option>
                                                    <option value="AB+">AB+</option>
                                                    <option value="AB-">AB-</option>
                                                    <option value="O+">O+</option>
                                                    <option value="O-">O-</option>
                                                </select>
                                            </div>
                                            <div className="col-md-12 mb-3">
                                                <div className="d-grid">
                                                    <button className="btn btn-primary" type="submit" disabled={loading}>
                                                        {loading ? (
                                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                        ) : (
                                                            'Register'
                                                        )}
                                                    </button>
                                                </div>
                                            </div>
                                            {error && <div className="col-md-12 mb-3"><p className="text-danger text-center">{error}</p></div>}
                                            {success && <div className="col-md-12 mb-3"><p className="text-success text-center">{success}</p></div>}
                                            <div className="col-lg-12 mt-2 text-center">
                                                <h6 className="text-muted">Or</h6>
                                            </div>
                                            <div className="text-center mt-1">
                                                <p className="mb-0">
                                                    <small className="text-dark me-1">Already have an account?</small> 
                                                    <Link to="/login" className="text-dark fw-bold">Sign in</Link>
                                                </p>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ToastContainer />
        </>
    );
    
}
