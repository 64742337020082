import React, { useEffect, useState } from "react";
import Navbar from "../../components/navbar";
import Sidebar from "../../components/sidebar";
import AdminFooter from "../../components/dashboard/adminFooter";
import ScrollTop from "../../components/scrollTop";
import client1 from "../../assets/images/client/01.jpg";
import axios from "axios";
import Paging from "../../components/CommonComponents/Paging";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../components/CommonComponents/Header";

export default function PatientReview() {
  const [patientsData, setPatientsData] = useState([]);
  const doctorId = localStorage.getItem("doctorId");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [selectedRating, setSelectedRating] = useState(null);

  const filteredReviews = selectedRating
    ? patientsData.filter(review => review.rating === selectedRating)
    : patientsData;

  const totalPages = Math.ceil(filteredReviews.length / pageSize);
  const indexOfLastReview = currentPage * pageSize;
  const indexOfFirstReview = indexOfLastReview - pageSize;
  const currentReview = filteredReviews.slice(indexOfFirstReview, indexOfLastReview);
const navigate = useNavigate();
const location =useLocation();
const showBackButton = location.key !== 'default';

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleRatingSelect = (rating) => {
    setSelectedRating(rating);
    setCurrentPage(1); // Reset to first page
  };

  useEffect(() => {
    const fetchRatings = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/doctors/${doctorId}/ratings/`
        );
        setPatientsData(response.data);
      } catch (error) {
        console.error("Error fetching ratings data:", error);
      }
    };

    fetchRatings();
  }, [doctorId]);

  useEffect(() => {
    setCurrentPage(1);
  }, [selectedRating]);

  const averageRating =
    patientsData.length > 0
      ? (
          patientsData.reduce((acc, curr) => acc + curr.rating, 0) /
          patientsData.length
        ).toFixed(1)
      : 0;

  const ratingDistribution = [5, 4, 3, 2, 1].map((star) => {
    const count = patientsData.filter((item) => item.rating === star).length;
    const percentage = patientsData.length > 0 ? (count / patientsData.length) * 100 : 0;
    return {
      star,
      count,
      percentage: percentage,
    };
  });

  return (
    <>
      <Navbar
        navDark={true}
        manuClass="navigation-menu nav-left"
        containerClass="container"
      />
      <section className="bg-dashboard">
        <div className="container-fluid">
          <div className="row">
            <Sidebar colClass="col-xl-3 col-lg-4 col-md-5 col-12 d-none d-lg-block" />

            <div className="col-xl-9 col-lg-8 col-md-12">
              {/* <h5 className="mb-0">Patients Review</h5> */}
              <Header title="Patients Review" 
              onBackClick={() => navigate(-1)}
              showBackButton={showBackButton}
              
              />
              <div className="row">
                <div className="col-xl-3 col-lg-5 col-md-5 col-12 mt-4 pt-2">
                  <div className="card p-4 border-0 shadow rounded">
                    <div>
                      <span
                        className="text-primary h1 mb-0"
                        style={{ cursor: 'default' }}
                      >
                        <span className="fw-bold">{averageRating}</span>
                      </span>
                      <span className="h6 align-self-end ms-2">
                        Avg. Ratings..
                      </span>
                    </div>

                    {/* Always Display Rating Filters */}
                    <div className="mt-4">
                      {ratingDistribution.map((item, index) => (
                        <div
                          className={`progress-box mt-4 ${selectedRating === item.star ? 'selected' : ''}`}
                          key={index}
                          onClick={() => handleRatingSelect(item.star)}
                          style={{ cursor: 'pointer' }}
                        >
                          <div className="d-flex justify-content-between">
                            <h6 className="title text-muted">{item.star} Star</h6>
                            <div className="progress-value d-block text-muted h6">
                              {item.percentage.toFixed(1)}%
                            </div>
                          </div>
                          <div className="progress">
                            <div
                              className="progress-bar position-relative bg-primary"
                              style={{ width: `${item.percentage}%` }}
                            />
                          </div>
                        </div>
                      ))}
                      <div
                        className={`progress-box mt-4 ${selectedRating === null ? 'selected' : ''}`}
                        onClick={() => handleRatingSelect(null)}
                        style={{ cursor: 'pointer' }}
                      >
                        <div className="d-flex justify-content-between">
                          <h6 className="title text-muted">All Ratings</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-9 col-lg-7 col-md-7 col-12 mt-4 pt-2">
                  <div className="card p-4 rounded shadow border-0">
                    <div className="row">
                      {/* Review Cards */}
                      {currentReview.map((item, index) => (
                        <div className="col-xl-4 col-md-6 mt-4" key={index}>
                          <div className="text-center">
                            <img
                              src={item.image || client1}
                              className="img-fluid avatar avatar-small rounded-circle mx-auto shadow"
                              alt=""
                            />
                            <h6 className="text-primary mt-3">{item.user_name}</h6>
                            <p className="text-muted fw-normal fst-italic">
                              {item.review_msg}
                            </p>
                            <ul className="list-unstyled mb-0">
                              {[...Array(5)].map((_, i) => (
                                <li className="list-inline-item" key={i}>
                                  <i
                                    className={`mdi mdi-star${i < item.rating ? "" : "-outline"} text-warning`}
                                  ></i>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      ))}
                    </div>

                    {/* Pagination */}
                    {filteredReviews.length > 0 && (
                      <Paging
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <AdminFooter />
      <ScrollTop />
    </>
  );
}
