import React from "react";
import { useState, useEffect, useRef } from "react";
import { Alert } from "@mui/material";
import { Link } from "react-router-dom";
import dr1 from "../../assets/images/doctors/01.jpg";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { TextField } from "@mui/material";
import {
  getMaxDate,
  getUserData,
} from "../../components/CommonComponents/CommonFunction";
import "react-toastify/dist/ReactToastify.css";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";
import { ShowSucess, ShowError } from "../../_helper/helper";
import { Card, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TimetableModal from "./TimetableModal.js";
import { Tab, Nav } from "react-bootstrap"; // Ensure you import these

export default function BookingAppointment({
  show,
  handleClose,
  selectedDoctor,
}) {
  const nagative = useNavigate();
  const [doctors, setDoctors] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [filteredDoctors, setFilteredDoctors] = useState([]);
  const [isBooked, setIsBooked] = useState(false);
  const [appointmentId, setAppointmentId] = useState(null);
  const [amount, setAmount] = useState("");
  const [orderId, setOrderId] = useState(null);
  const [razorpayKey, setRazorpayKey] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [availableSlots, setAvailableSlots] = useState([]); // New state for available slots
  const [unavailableDates, setUnavailableDates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const userData = getUserData();
  const isFirstRender = useRef(true);
  const [formData, setFormData] = useState({
    name: "",
    department: "",
    doctor: "",
    email: "",
    phone: "",
    date: "",
    time: "",
    comments: "",
    age: "",
    gender: "",
    clinic: "",
  });
  const token = localStorage.getItem("accessToken");
  const baseURL = process.env.REACT_APP_API_URL;
  const {
    first_name,
    last_name,
    contact_no,
    charge,
    image,
    hospital_clinic_affiliation,
    experience,
    country,
    address,
    specialization,
    email,
    specialities,
    average_rating,
  } = selectedDoctor || {};

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Update form data
    let newValue = value;

    // Handle specific cases
    if (name === "phone") {
      // Allow only numeric input for phone numbers
      newValue = value.replace(/\D/g, "");
    }

    // Set the new value in form data
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    // Fetch available slots if a valid date is selected
    if (name === "date" && newValue) {
      fetchAvailableSlots(formData.doctor, newValue);
    }
  };
  const handleDateChange = (date) => {
    if (date) {
      // Adjust the date to ensure it matches the local time zone
      const localDate = new Date(
        date.getTime() - date.getTimezoneOffset() * 60000
      );
      const formattedDate = localDate.toISOString().split("T")[0];
  
      // Update form data with the selected date
      setFormData((prevFormData) => ({
        ...prevFormData,
        date: formattedDate,
      }));
  
      // Fetch available slots if the doctor is already selected
      if (formData.doctor) {
        fetchAvailableSlots(formData.doctor, formattedDate);
      }
    } else {
      // Handle the case when the date is cleared
      setFormData((prevFormData) => ({
        ...prevFormData,
        date: null, // Clear the date in form data
      }));
      setAvailableSlots([]);
    }
  };
  

  //   const handleDateChange = (date) => {
  //     const formattedDate = date ? date.toISOString().split("T")[0] : "";

  //     // Update form data with the selected date
  //     setFormData((prevFormData) => ({
  //       ...prevFormData,
  //       date: formattedDate,
  //     }));

  //     // Fetch available slots if the doctor is already selected
  //     if (formattedDate && formData.doctor) {
  //       fetchAvailableSlots(formData.doctor, formattedDate);
  //     }
  //   };

  useEffect(() => {
    // Fetch unavailable dates if doctor is selected
    if (formData.doctor) {
      fetchUnavailableDates(formData.doctor);
    }
  }, [formData.doctor]);

  const fetchUnavailableDates = async (doctorId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/unavailable-dates/?doctor_id=${doctorId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();
      if (Array.isArray(result)) {
        setUnavailableDates(result.map((date) => new Date(date.date)));
      } else {
        console.error("Unexpected response structure:", result);
      }
    } catch (error) {
      console.error("Error fetching unavailable dates:", error);
    }
  };

  //   const isDateUnavailable = (date) => {
  //     return unavailableDates.some(
  //       (unavailableDate) =>
  //         unavailableDate.toDateString() === date.toDateString()
  //     );
  //   };

  const handlePayment = async (event) => {
    event.preventDefault();
    handleClose();
    setIsBooked(false);
    try {
      const response = await axios.post(
        `${baseURL}/create-order/`,
        {
          amount: selectedDoctor?.charge,
          currency: "INR",
          order_type: "appointment",
          reference_id: appointmentId,
          appointment_id: appointmentId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const {
        order_id,
        order,
        razorpay_key,
        // amount: orderAmount,
        currency,
      } = response.data;

      const { id, amount: orderAmount } = order || {};
      setOrderId(id);
      setRazorpayKey(razorpay_key);
      setError(null);
      displayRazorpay(
        id,
        order_id,
        Number(orderAmount),
        currency,
        razorpay_key
      );
    } catch (err) {
      setError("Order creation failed. Please try again.");
    }
  };
  const displayRazorpay = async (id, order_id, amount, currency, key) => {
    const options = {
      key: key,
      amount: amount,
      currency: currency,
      order_id: order_id,
      handler: async function (response) {
        try {
          const result = await axios.put(
            `${baseURL}/verify-payment/${id}/`,
            {
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_order_id: response.razorpay_order_id,
              razorpay_signature: response.razorpay_signature,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          if (result.data.status === "Payment successful") {
            setSuccess(true);
            ShowSucess("Payment successful");
            setError(null);
          } else {
            ShowError("Payment verification failed. Please try again.");
          }
        } catch (err) {
          ShowError("Payment verification failed. Please try again.");
        }
      },
      prefill: {
        name: `${userData?.user?.first_name} ${userData?.user?.last_name}`,
        email: userData?.user?.email,
        contact: userData?.user?.contact_no,
      },
      theme: {
        color: "#3399cc",
      },
    };
    const rzp = new window.Razorpay(options);
    rzp.open();
  };
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/doctors`)
      .then((response) => response.json())
      .then((data) => {
        setDoctors(data);
        const uniqueDepartments = [
          ...new Set(data.map((doc) => doc.specialization)),
        ];
        setDepartments(uniqueDepartments);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);
  const [clinicList, setClinicList] = useState("");
  useEffect(() => {
    if (selectedDepartment) {
      const filtered = doctors.filter(
        (doc) => doc.specialization === selectedDepartment
      );
      setFilteredDoctors(filtered);
    } else {
      setFilteredDoctors(doctors);
    }
  }, [selectedDepartment, doctors]);

  useEffect(() => {
    // Skip the effect on the first render
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    console.log("selectedDoctor", selectedDoctor);
    if (selectedDoctor && userData?.user) {
      const { first_name, last_name, age, email, phone_number, gender } =
        userData?.user;

      // Check if formData needs to be updated to prevent unnecessary updates
      // if (
      //   formData.name !== `${first_name} ${last_name}` ||
      //   formData.email !== email ||
      //   formData.phone !== phone_number ||
      //   formData.gender !== gender ||
      //   formData.age !== age ||
      //   formData.doctor !== selectedDoctor.id ||
      //   formData.department !== selectedDoctor.specialization
      // ) {

      //   // setAmount(selectedDoctor?.charge);
      // }
      setFormData({
        ...formData,
        name: `${first_name} ${last_name}`,
        email: email,
        phone: phone_number,
        gender: gender,
        age: age,
        doctor: selectedDoctor.id,
        department: selectedDoctor.specialization,
      });
      setSelectedDepartment(selectedDoctor.specialization);
    }
  }, [selectedDoctor]);

  //   const handleSubmit = (e) => {
  //     e.preventDefault();
  //     setLoading(true);
  //     const payload = {
  //       doctor: formData.doctor,
  //       patient_name: formData.name,
  //       patient: localStorage.getItem("userId"),
  //       appointment_date: formData.date,
  //       appointment_time: formData.time,
  //       Clinicname_address:formData.clinic,
  //       status: "pending",
  //       reason: formData.comments,
  //       active: true,
  //       age: formData.age,
  //       gender: formData.gender,
  //       patient_email: formData.email,
  //       appointment_type: bookingType,
  //     };

  //     fetch(`${process.env.REACT_APP_API_URL}/appointments/create/`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${token}`,
  //       },
  //       body: JSON.stringify(payload),
  //     })
  //       .then((response) => response.json())
  //       .then((data) => {
  //         if (data?.id) {
  //           toast.success("Appointment booked successfully!");
  //           setIsBooked(true);
  //           setFormData({
  //             name: "",
  //             doctor: "",
  //             email: "",
  //             phone: "",
  //             date: "",
  //             time: "",
  //             comments: "",
  //             age: "",
  //             gender: "",
  //             clinic:"",
  //           });
  //         } else {
  //           ShowError(data?.detail);
  //         }

  //         setLoading(false);
  //       })
  //       .catch((error) => {
  //         console.error("Error booking appointment:", error);
  //         toast.error("Failed to book appointment. Please try again.");
  //         setLoading(false);
  //       });
  //   };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    let tokenData = null;

    // If booking type is "online", generate the token first
    if (bookingType === "online") {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/video/generate-token/`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              api_key:
                "vpaas-magic-cookie-ae16a235bbbc4d6ead0e00b8a0ba947a/9c5df1",
              user_name: formData.name,
              user_email: formData.email,
              is_moderator: true,
              app_id: "vpaas-magic-cookie-ae16a235bbbc4d6ead0e00b8a0ba947a",
              avatar_url: "http://example.com/avatar.jpg",
            }),
          }
        );

        const data = await response.json();
        if (response.ok) {
          console.log("Token generated successfully:", data);
          tokenData = data;
        } else {
          console.error("Failed to generate token:", data);
          toast.error("Failed to generate token. Please try again.");
          setLoading(false);
          return; // Stop submission if token generation fails
        }
      } catch (error) {
        console.error("Error generating token:", error);
        toast.error("Error generating token. Please try again.");
        setLoading(false);
        return; // Stop submission if token generation fails
      }
    }
    const selectedClinic = clinicList.find(
      (clinic) => clinic.clinic_name === formData.clinic
    );
  
    const clinicAddress = selectedClinic
      ? `${selectedClinic.clinic_name}, ${selectedClinic.clinic_address}`
      : formData.clinic;
    // Prepare the payload
    const payload = {
      doctor: formData.doctor,
      patient_name: formData.name,
      patient: localStorage.getItem("userId"),
      appointment_date: formData.date,
      appointment_time: formData.time,
      Clinicname_address: clinicAddress,
      status: "pending",
      reason: formData.comments,
      active: true,
      age: formData.age,
      gender: formData.gender,
      patient_email: formData.email,
      appointment_type: bookingType,
      //   token: tokenData?.token,  // Include token if it was generated
    };

    // Submit the booking form
    fetch(`${process.env.REACT_APP_API_URL}/appointments/create/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("data", data);
        if (data?.id) {
          setAppointmentId(data?.id);
          toast.success("Appointment booked successfully!");
          setIsBooked(true);
          setFormData({
            name: "",
            doctor: "",
            email: "",
            phone: "",
            date: "",
            time: "",
            comments: "",
            age: "",
            gender: "",
            clinic: "",
          });
        } else {
          ShowError(data?.detail);
        }

        setLoading(false);
      })
      .catch((error) => {
        console.error("Error booking appointment:", error);
        toast.error("Failed to book appointment. Please try again.");
        setLoading(false);
      });
  };

  const modalRef = useRef();
  const resetForm = () => {
    setFormData({
      name: "",
      department: "",
      doctor: "",
      email: "",
      phone: "",
      date: "",
      time: "",
      comments: "",
      age: "",
      gender: "",
      clinic: "",
    });
    setSelectedDepartment("");
  };
  const handleModalClose = () => {
    resetForm(); // Clear the form data
    handleClose(); // Close the modal
  };
  const handleModalClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      e.stopPropagation(); // Prevent closing when clicking inside the modal
    }
  };

  // Function to get today's date in yyyy-mm-dd format
  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const fetchAvailableSlots = async (doctorId, date) => {
    try {
      const formattedDate = date.split("T")[0]; // Extract date part
      const today = new Date().toISOString().split("T")[0];
      const currentTime = new Date().toTimeString().split(" ")[0]; // Get current time in HH:MM:SS format

      const response = await axios.get(
        `${baseURL}/doctor/${doctorId}/available-slots/?date=${formattedDate}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data && response.data.length > 0) {
        // Filter slots based on time if today
        const isToday = formattedDate === today;
        const slots = isToday
          ? response.data.filter((slot) => slot.start_time > currentTime)
          : response.data;

        setAvailableSlots(slots);
      } else {
        // Clear available slots if no data is returned
        setAvailableSlots([]);
      }
    } catch (error) {
      console.error("Error fetching available slots:", error);
      // Clear available slots on error
      setAvailableSlots([]);
    }
  };

  const convertTo12HourFormat = (time) => {
    const [hour, minute] = time.split(":");
    const formattedHour = parseInt(hour, 10);
    const period = formattedHour >= 12 ? "PM" : "AM";
    const twelveHour = formattedHour % 12 || 12; // Converts 0 to 12
    return `${twelveHour}:${minute} ${period}`;
  };

  const [bookingType, setBookingType] = useState("clinic"); // Track whether Clinic Visit or Online Booking is selected

  const handleBookingTypeSelect = (type) => {
    setBookingType(type);
  };
  //   useEffect(() => {
  //     const fetchTimetables = async () => {
  //       if (!selectedDoctor.id) return;
  //       const id = selectedDoctor.id;
  //       setLoading(true);
  //       try {
  //         const response = await fetch(
  //           `${process.env.REACT_APP_API_URL}/doctors/details/${id}/`
  //         );
  //         if (!response.ok) {
  //           throw new Error("Network response was not ok");
  //         }
  //         const data = await response.json();
  //         const sortedTimetables = data.timetables.sort(
  //           (a, b) =>
  //             daysOfWeek.indexOf(a.day_of_week) -
  //             daysOfWeek.indexOf(b.day_of_week)
  //         );

  //         // Get available days from timetable
  //         const availableDays = new Set(
  //           sortedTimetables.map((t) => t.day_of_week)
  //         );

  //         // Find unavailable days
  //         const unavailableDays = daysOfWeek.filter(
  //           (day) => !availableDays.has(day)
  //         );

  //         // Get dates for unavailable days within the next 15 days
  //         const today = new Date();
  //         const upcomingUnavailableDates = getDatesForUnavailableDays(
  //           unavailableDays,
  //           today
  //         );

  //         setUnavailableDates(upcomingUnavailableDates);
  //       } catch (error) {
  //         setError(error.message);
  //       } finally {
  //         setLoading(false);
  //       }
  //     };

  //     if (selectedDoctor) {
  //       fetchTimetables();
  //     }
  //   }, [selectedDoctor]);
  // useEffect(() => {
  //     const fetchTimetables = async () => {
  //       if (!selectedDoctor.id) return;
  //   const generateToken = async () => {
  //     try {
  //       const response = await fetch(`${process.env.REACT_APP_API_URL}/video/generate-token/`, {
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/json',
  //         },
  //         body: JSON.stringify({
  //           api_key: 'vpaas-magic-cookie-ae16a235bbbc4d6ead0e00b8a0ba947a/9c5df1',
  //           user_name: 'mohd A',
  //           user_email: '01mohdasif03@gmail.com',
  //           is_moderator: true,
  //           app_id: 'vpaas-magic-cookie-ae16a235bbbc4d6ead0e00b8a0ba947a',
  //           avatar_url: 'http://example.com/avatar.jpg'
  //         }),
  //       });

  //       const data = await response.json();

  //       if (response.ok) {
  //         console.log('Token generated successfully:', data);
  //         // Handle successful token generation here
  //       } else {
  //         console.error('Failed to generate token:', data);
  //         // Handle error here
  //       }
  //     } catch (error) {
  //       console.error('Error generating token:', error);
  //       // Handle error here
  //     }
  //   };

  const [availableDays, setAvailableDays] = useState([]);

  //       setLoading(true);
  //       try {
  //         const response = await fetch(
  //           `${process.env.REACT_APP_API_URL}/doctors/details/${selectedDoctor.id}/`
  //         );
  //         if (!response.ok) {
  //           throw new Error("Network response was not ok");
  //         }
  //         const data = await response.json();

  //         // Extract clinic information
  //         const clinics = data.timetables.map(timetable => ({
  //           clinic_name: timetable.clinic_name,
  //           clinic_address: timetable.clinic_address
  //         }));

  //         // Set clinicList
  //         setClinicList(clinics);
  //       } catch (error) {
  //         setError(error.message);
  //       } finally {
  //         setLoading(false);
  //       }
  //     };

  //     if (selectedDoctor) {
  //       fetchTimetables();
  //     }
  //   }, [selectedDoctor]);
  //   const [availableDays, setAvailableDays] = useState([]);

  useEffect(() => {
    const fetchTimetables = async () => {
      if (!selectedDoctor.id) return;

      setLoading(true);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/doctors/details/${selectedDoctor.id}/`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();

        // Extract available days of the week from timetable
        const availableDays = data.timetables
          .map((timetable) => timetable.day_of_week)
          .filter((day) => day); // Filter out any null or undefined days

        setAvailableDays(availableDays);
        setClinicList(
          data.timetables.map((timetable) => ({
            clinic_name: timetable.clinic_name,
            clinic_address: timetable.clinic_address,
          }))
        );
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    if (selectedDoctor) {
      fetchTimetables();
    }
  }, [selectedDoctor]);
  // Utility function to check if a date is selectable
  const isDateSelectable = (date) => {
    const dayOfWeek = date.getDay(); // 0 = Sunday, 1 = Monday, 2 = Tuesday, etc.
    const dayNames = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const dayName = dayNames[dayOfWeek];
    return availableDays.includes(dayName);
  };

  // Function to check if a date is unavailable (based on your requirements)
  //   const isDateUnavailable = (date) => !isDateSelectable(date);
  const isDateUnavailable = (date) => {
    // Check if the date is not selectable based on the day of the week
    const selectable = isDateSelectable(date);

    // Check if the date is in the list of unavailable dates
    const unavailable = unavailableDates.some(
      (unavailableDate) =>
        unavailableDate.toDateString() === date.toDateString()
    );

    // A date is unavailable if it is not selectable or if it is in the list of unavailable dates
    return !selectable || unavailable;
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleModalClose}
        size="lg"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Book an Appointment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card>
            <Card.Body>
              <h5 className="text-align-center">
                {hospital_clinic_affiliation}
              </h5>
              <div>
                <div className="row">
                  <div className="col-lg-8 col-md-6">
                    <Card.Img
                      variant="top"
                      src={image || dr1}
                      alt="doctor-profile"
                      className="rounded-circle"
                      style={{ width: "60px", height: "60px" }}
                    />
                    <Card.Text className="text-muted-bold mb-1">
                      {`${first_name} ${last_name}`}
                    </Card.Text>
                    <Card.Text className="text-muted mb-4">
                      {`${address}, ${country}`}
                    </Card.Text>
                    <div>
                      <a
                        href="#"
                        style={{
                          color: "#007bff", // Bootstrap primary color
                          textDecoration: "none",
                          fontWeight: "bold",
                          cursor: "pointer",
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          handleOpenModal();
                        }}
                      >
                        View TimeTable
                      </a>
                      {isModalOpen && (
                        <TimetableModal
                          id={selectedDoctor.id}
                          handleCloseModal={handleCloseModal}
                        />
                      )}
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                    <ul className="list-unstyled">
                      <li className="d-flex mt-2 ms-0">
                        <small className="mb-0 text-muted">
                          Specialization :
                        </small>
                        <small className="mb-0 text-dark">
                          &nbsp;&nbsp;{specialization}
                        </small>
                      </li>
                      <li className="d-flex mt-2 ms-0 align-items-start">
                        <small className="mb-0 text-muted me-1">
                          Experience:
                        </small>
                        <small className="mb-0 text-dark">
                          {experience && experience[0]?.description}
                        </small>
                      </li>

                      <li className="d-flex mt-2 ms-0">
                        <small className="mb-0 text-muted">Avg Ratings :</small>
                        <small className="mb-0 text-dark">
                          &nbsp;&nbsp;{average_rating}
                        </small>
                      </li>
                      <li className="d-flex mt-2 ms-0">
                        <small className="mb-0 text-muted">Charge :</small>
                        <small className="mb-0 text-dark">
                          &nbsp;&nbsp;{charge} ₹
                        </small>
                      </li>
                      <li className="d-flex mt-2 ms-0">
                        <small className="mb-0 text-muted">Email :</small>
                        <small className="mb-0 text-dark">{email}</small>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>

          {/* Booking Type Selection */}
          <div className="row mb-4">
            <div className="col-6 p-0">
              <button
                className={`btn btn-outline-primary w-100 ${
                  bookingType === "clinic" ? "active" : ""
                }`}
                onClick={() => handleBookingTypeSelect("clinic")}
                style={{
                  borderRadius: "0",
                  marginRight: "0",
                  backgroundColor:
                    bookingType === "clinic" ? "#007bff" : "#f8f9fa", // Grey background
                  color: bookingType === "clinic" ? "#fff" : "#000", // White text when clicked, black otherwise
                  fontWeight: bookingType === "clinic" ? "bold" : "bold", // Bold text
                  borderColor: "transparent", // Remove border color
                }}
              >
                Clinic Visit
              </button>
            </div>
            <div className="col-6 p-0">
              <button
                className={`btn btn-outline-primary w-100 ${
                  bookingType === "online" ? "active" : ""
                }`}
                onClick={() => handleBookingTypeSelect("online")}
                style={{
                  borderRadius: "0",
                  marginLeft: "0",
                  backgroundColor:
                    bookingType === "online" ? "#007bff" : "#f8f9fa", // Grey background
                  color: bookingType === "online" ? "#fff" : "#000", // White text when clicked, black otherwise
                  fontWeight: bookingType === "online" ? "bold" : "bold", // Bold text
                  borderColor: "transparent", // Remove border color
                }}
              >
                Online Booking
              </button>
            </div>
          </div>

          {/* Conditionally Render Forms */}
          {bookingType && (
            <form onSubmit={handleSubmit}>
              <div className="row">
                {/* Common Fields for Both Booking Types */}
                <div className="col-lg-12">
                  <div className="mb-3">
                    <label className="form-label">
                      Patient Name <span className="text-danger">*</span>
                    </label>
                    <input
                      name="name"
                      id="name"
                      type="text"
                      className="form-control"
                      placeholder="Patient Name :"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="form-label">
                      Patient Age <span className="text-danger">*</span>
                    </label>
                    <input
                      name="age"
                      id="age"
                      type="number"
                      className="form-control"
                      placeholder="Patient Age :"
                      value={formData.age}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="form-label">
                      Gender <span className="text-danger">*</span>
                    </label>
                    <select
                      name="gender"
                      id="gender"
                      className="form-control"
                      value={formData.gender}
                      onChange={handleChange}
                      required
                    >
                      <option value="" disabled>
                        Select Gender
                      </option>
                      <option value="M">Male</option>
                      <option value="F">Female</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="form-label">
                      Your Email <span className="text-danger">*</span>
                    </label>
                    <input
                      name="email"
                      id="email"
                      type="email"
                      className="form-control"
                      placeholder="Your email :"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="form-label">
                      Your Phone <span className="text-danger">*</span>
                    </label>
                    <input
                      name="phone"
                      id="phone"
                      type="tel"
                      className="form-control"
                      placeholder="Your Phone :"
                      value={formData.phone}
                      onChange={handleChange}
                      pattern="[0-9]*"
                      required
                    />
                  </div>
                </div>

                {/* Clinic Booking Specific Fields */}
                {bookingType === "clinic" && (
                  <div className="row">
                    {/* Clinic, Date, and Time in the same row */}
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="clinic" className="form-label">
                          Select Clinic <span className="text-danger">*</span>
                        </label>
                        <select
                          id="clinic"
                          name="clinic"
                          className="form-select form-control"
                          value={formData.clinic}
                          onChange={handleChange}
                          required
                        >
                          <option value="">Select Clinic</option>
                          {Array.isArray(clinicList) &&
                          clinicList.length > 0 ? (
                            clinicList.map((clinic, index) => (
                              <option key={index} value={clinic.clinic_name}>
                                {clinic.clinic_name} - {clinic.clinic_address}
                              </option>
                            ))
                          ) : (
                            <option disabled>No clinics available</option>
                          )}
                        </select>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="date" className="form-label">
                          Date <span className="text-danger">*</span>
                        </label>
                        <div
                          className="date-picker-wrapper"
                          style={{ position: "relative" }}
                        >
                          <DatePicker
                            selected={
                              formData.date
                                ? new Date(formData.date)
                                : undefined
                            }
                            onChange={handleDateChange}
                            filterDate={(date) => !isDateUnavailable(date)}
                            minDate={new Date()}
                            maxDate={getMaxDate()}
                            dateFormat="dd-MM-yyyy"
                            className="form-select form-control"
                            placeholderText="dd-mm-yyyy"
                            required
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="time" className="form-label">
                          Time <span className="text-danger">*</span>
                        </label>
                        <select
                          id="time"
                          name="time"
                          className="form-select form-control"
                          value={formData.time}
                          onChange={handleChange}
                          required
                        >
                          <option value="">Select Time</option>
                          {availableSlots.length > 0 ? (
                            availableSlots.map((slot, index) => (
                              <option key={index} value={slot.start_time}>
                                {convertTo12HourFormat(slot.start_time)}
                              </option>
                            ))
                          ) : (
                            <option value="" disabled>
                              No available slots
                            </option>
                          )}
                        </select>
                      </div>
                    </div>
                  </div>
                )}

                {/* Online Booking Specific Fields */}
                {bookingType === "online" && (
                  <>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="form-label">
                          Date <span className="text-danger">*</span>
                        </label>
                        <div
                          className="date-picker-wrapper"
                          style={{ position: "relative" }}
                        >
                          <DatePicker
                            selected={
                              formData.date
                                ? new Date(formData.date)
                                : undefined
                            }
                            onChange={handleDateChange}
                            filterDate={(date) => !isDateUnavailable(date)}
                            minDate={new Date()}
                            maxDate={getMaxDate()}
                            dateFormat="dd-MM-yyyy"
                            className="form-select form-control"
                            placeholderText="dd-mm-yyyy"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="form-label">
                          Time <span className="text-danger">*</span>
                        </label>
                        <select
                          name="time"
                          className="form-select form-control"
                          value={formData.time}
                          onChange={handleChange}
                          required
                        >
                          <option value="">Select Time</option>
                          {availableSlots.length > 0 ? (
                            availableSlots.map((slot, index) => (
                              <option key={index} value={slot.start_time}>
                                {convertTo12HourFormat(slot.start_time)}
                              </option>
                            ))
                          ) : (
                            <option value="" disabled>
                              No available slots
                            </option>
                          )}
                        </select>
                      </div>
                    </div>
                  </>
                )}

                {/* Common Fields */}
                <div className="col-lg-12">
                  <div className="mb-3">
                    <label className="form-label">
                      Reason for Appointment{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <textarea
                      name="comments"
                      id="comments"
                      rows="4"
                      className="form-control"
                      placeholder="Your Message :"
                      value={formData.comments}
                      onChange={handleChange}
                      required
                    ></textarea>
                  </div>
                </div>

                {/* Submission Button */}
                <div className="col-lg-12 d-flex flex-column align-items-center">
                  {isBooked && (
                    <div className="mb-3">
                      <Alert severity="success" style={{ margin: "10px 0px" }}>
                        Your appointment has been successfully booked. Please
                        proceed with the payment.
                      </Alert>
                    </div>
                  )}

                  <button
                    type="submit"
                    onClick={isBooked ? (e) => handlePayment(e) : undefined}
                    className="btn btn-primary"
                    disabled={loading}
                    style={{ width: "300px" }}
                  >
                    {loading ? (
                      <>
                        <Spinner
                          animation="border"
                          size="sm"
                          className="me-2"
                        />
                        Processing...
                      </>
                    ) : isBooked ? (
                      "Make a Payment"
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
              </div>
            </form>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
