import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export const ShowSucess = (message) => {
  if (message === undefined) {
    return;
  }
  toast(`${message}`, {
    theme: "light",
    position: "top-right",
  });
  <ToastContainer />;
};
export const ShowError = (message) => {
  if (message === undefined) {
    return;
  }
  <ToastContainer />;
  toast.error(`${message}`, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
    style: {
      width: "auto",
      height: "auto",
    },
  });
};
