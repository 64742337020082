// import React, { useState, useEffect } from "react";
// import { Link, useLocation, useNavigate } from "react-router-dom";
// import Navbar from "../../components/navbar";
// import Footer from "../../components/footer";
// import ScrollTop from "../../components/scrollTop";
// import {
//   FiHeart,
//   FiPhone,
//   RiMoneyDollarCircleLine,
//   RiMapPinLine,
// } from "../../assets/icons/vander";
// import Pagination from "../../components/CommonComponents/Pagination";
// import BookingAppointment from "../patient/booking-appointment";
// import dr1 from "../../assets/images/doctors/Avatar.jpg";
// import SkeletonLoader from "./SkeletonLoader";
// import "./doctorstyle.css";
// import MobileLogin from "../../components/CommonComponents/MobileLogin";
// import LoginSignUp from "../../components/CommonComponents/LoginSignUp";

// export default function DoctorTeamTwo() {
//   const [searchQuery, setSearchQuery] = useState("");
//   const [doctors, setDoctors] = useState([]);
//   const [allDoctors, setAllDoctors] = useState([]);
//   const [searchResults, setSearchResults] = useState([]); // State for search results
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [pageSize, setPageSize] = useState(25);
//   const [totalPages, setTotalPages] = useState(1);

//   const [modalShow, setModalShow] = useState(false);
//   const [selectedDoctor, setSelectedDoctor] = useState(null);
//   const [showLoginPopup, setShowLoginPopup] = useState(false); // State for mobile login popup
//   const [showLoginSignUpModal, setShowLoginSignUpModal] = useState(false);
//   const [userRole, setUserRole] = useState(null);
//   const storedUserRole = localStorage.getItem("userRole");

//   const navigate = useNavigate();
//   const location = useLocation();

//   useEffect(() => {
//     // Extract search query from URL parameters
//     const queryParams = new URLSearchParams(location.search);
//     const searchTerm = queryParams.get("search") || "";
//     setSearchQuery(searchTerm);
//   }, [location.search]);

//   useEffect(() => {
//     // Assume userRole is stored in localStorage after login
//     if (storedUserRole) {
//       setUserRole(storedUserRole);
//     }
//   }, [storedUserRole]);
//   useEffect(() => {
//     async function fetchDoctors() {
//       try {
//         setLoading(true);
//         const response = await fetch(
//           `${process.env.REACT_APP_API_URL}/getdoctorsdata/doctorslist/?page=${currentPage}&page_size=${pageSize}`
//         );
//         if (!response.ok) {
//           throw new Error("Network response was not ok");
//         }
//         const data = await response.json();
//         setAllDoctors(data.results);
//         setDoctors(data.results);
//         setTotalPages(Math.ceil(data.count / pageSize));
//         setError(null);
//       } catch (error) {
//         console.error("Error fetching doctors:", error);
//         setError(error.message);
//       } finally {
//         setLoading(false);
//       }
//     }

//     fetchDoctors();
//   }, [currentPage, pageSize]);

//   useEffect(() => {
//     if (searchQuery.trim() === "") {
//       setSearchResults([]);
//       setDoctors(allDoctors); // Reset to all doctors when search is cleared
//     } else {
//       searchDoctors(searchQuery);
//     }
//   }, [searchQuery, allDoctors]);

//   async function searchDoctors(query) {
//     try {
//       setLoading(true);
//       const response = await fetch(
//         `${
//           process.env.REACT_APP_API_URL
//         }/doctors/search/?search=${encodeURIComponent(query)}`
//       );
//       if (!response.ok) {
//         throw new Error("Network response was not ok");
//       }
//       const data = await response.json();
//       setSearchResults(data); // Update search results with the API response
//       setDoctors(data.length ? data : []); // Update doctors based on search results
//       setError(null);
//     } catch (error) {
//       console.error("Error fetching search results:", error);
//       setError(error.message);
//       setDoctors([]); // Clear doctors on error
//     } finally {
//       setLoading(false);
//     }
//   }

//   const handlePageChange = (pageNumber) => {
//     if (pageNumber > 0 && pageNumber <= totalPages) {
//       setCurrentPage(pageNumber);
//     }
//   };

//   const handlePageSizeChange = (event) => {
//     setPageSize(Number(event.target.value));
//     setCurrentPage(1);
//   };

//   const renderStars = (averageRating) => {
//     const totalStars = 5;
//     const rating = Math.max(0, Math.min(5, Number(averageRating) || 0));
//     const filledStars = Math.floor(rating);
//     const halfStar = rating - filledStars >= 0.5 ? 1 : 0;
//     const emptyStars = totalStars - filledStars - halfStar;

//     const starElements = [];
//     starElements.push(
//       ...Array(filledStars).fill(
//         <i
//           className="mdi mdi-star text-warning"
//           key={`filled-${starElements.length}`}
//         ></i>
//       )
//     );
//     if (halfStar) {
//       starElements.push(
//         <i
//           className="mdi mdi-star-half text-warning"
//           key={`half-${starElements.length}`}
//         ></i>
//       );
//     }
//     starElements.push(
//       ...Array(emptyStars).fill(
//         <i
//           className="mdi mdi-star-outline text-warning"
//           key={`empty-${starElements.length}`}
//         ></i>
//       )
//     );

//     return <>{starElements}</>;
//   };

//   const handleAppointmentClick = (doctor) => {
//     if (!userRole) {
//       // navigate("/login")
//       setShowLoginSignUpModal(true);
//       setSelectedDoctor(doctor);

//       // setShowLoginPopup(true);
//     } else {
//       setSelectedDoctor(doctor);
//       setModalShow(true);
//     }
//   };

//   const handleAppointmentButtonClick = () => {
//     if (!userRole) {
//       // navigate('/login')
//       setShowLoginPopup(true);
//     } else {
//       setSelectedDoctor(null);
//       setModalShow(true);
//     }
//   };
//   const renderExperience = (experience) => {
//     if (typeof experience === "string") {
//       return experience;
//     } else if (typeof experience === "object" && experience !== null) {
//       // If experience is an object, get the value from the first key
//       return Object.values(experience)[0] || "N/A";
//     }
//     return "N/A";
//   };

//   return (
//     <>
//       <Navbar
//         navDark={true}
//         manuClass="navigation-menu nav-left"
//         containerClass="container"
//       />

//       <section className="section">
//         <div className="container">
//           <div className="search-bar mt-4">
//             <input
//               type="text"
//               className="form-control"
//               placeholder="Search doctors..."
//               value={searchQuery}
//               onChange={(e) => setSearchQuery(e.target.value)}
//             />
//           </div>
        
//           <div className="row align-items-center">
//             {loading ? (
//               <SkeletonLoader />
//             ) : error ? (
//               <div className="col-12 text-center">
//                 <p className="text-muted">Error: {error}</p>
//               </div>
//             ) : doctors.length > 0 ? (
//               doctors.map((doctor) => (
//                 <div className="col-12 p-0" key={doctor.user}>
//                   <div
//                     className="card team border-0 rounded shadow overflow-hidden"
//                     style={{
//                       display: "flex",
//                       flexDirection: "row",
//                       width: "100%",
//                       padding: "15px",
//                       marginBottom: "0",
//                     }}
//                   >
//                     <div className="team-person" style={{ flexShrink: 0 }}>
//                       <img
//                         src={doctor.image || dr1}
//                         alt={doctor.first_name + " " + doctor.last_name}
//                         style={{
//                           width: "150px",
//                           height: "150px",
//                           borderRadius: "50%",
//                           objectFit: "cover",
//                         }}
//                       />
//                     </div>
//                     <div
//                       className="card-body"
//                       style={{
//                         flex: 1,
//                         display: "flex",
//                         flexDirection: "row",
//                         alignItems: "stretch",
//                       }}
//                     >
//                       <div style={{ flex: 1 }}>
//                         <div style={{ marginBottom: "15px" }}>
//                           <Link
//                             to={`/doctor-profile/${doctor.id}`}
//                             className="title text-dark h5 d-block mb-0"
//                           >
//                             {doctor.first_name} {doctor.last_name}
//                           </Link>
//                           <small className="text-muted speciality">
//                             {doctor.specialization} <br />
//                             {doctor.years_of_experience} Years Experience
//                           </small>
//                           <div className="d-flex align-items-center mt-2">
//                             <ul className="list-unstyled mb-0 me-2">
//                               {renderStars(doctor.average_rating)}
//                             </ul>
//                             <p className="text-muted mb-0 me-2">
//                             {parseFloat(doctor.average_rating).toFixed(2).replace(/\.00$/, '')} Star
//                             </p>
//                           </div>
//                           <ul className="list-unstyled mt-2 mb-0">
//                             <li className="d-flex ms-0">
//                               <RiMapPinLine className="text-primary align-middle" />
//                               <small className="text-muted ms-2">
//                                 {doctor.location}
//                               </small>
//                             </li>
//                             <li className="d-flex ms-0 mt-2">
//                               <FiPhone className="text-primary align-middle" />
//                               <small className="text-muted ms-2">
//                                 {doctor.contact_no}
//                               </small>
//                             </li>
//                             <li className="d-flex ms-0 mt-2">
//                               <RiMoneyDollarCircleLine className="text-primary align-middle" />
//                               <small className="text-muted ms-2">
//                                 {doctor.charge} INR
//                               </small>
//                             </li>
//                             <li className="d-flex ms-0 mb-2">
//                               <small className="text-muted ms-2">
//                                 {doctor.description}
//                               </small>
//                             </li>
//                           </ul>
//                         </div>
//                       </div>
//                       <div
//                         style={{
//                           width: "300px",
//                           marginLeft: "15px",
//                           display: "flex",
//                           flexDirection: "column",
//                           justifyContent: "space-between",
//                         }}
//                       >
//                         <ul className="list-unstyled mb-3">
//                           <li className="d-flex mb-2">
//                             <small className="text-muted ms-2">
//                               {doctor.hospital_clinic_affiliation}
//                             </small>
//                           </li>
//                           <li className="d-flex mb-2">
//                             <small className="text-muted ms-2">
//                               {doctor.medical_school}
//                             </small>
//                           </li>
//                           <li className="d-flex mb-2">
//                             <small className="text-muted ms-2">
//                               {doctor.street_address}
//                             </small>
//                           </li>
//                         </ul>
//                         <div className="text-end">
//                           <button
//                             className="btn btn-primary btn-3d"
//                             onClick={() => handleAppointmentClick(doctor)}
//                           >
//                             Appointment
//                           </button>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               ))
//             ) : (
//               <div className="col-12 text-center">
//                 <p className="text-muted">No doctors found</p>
//               </div>
//             )}
//           </div>

       

//           {doctors.length > 0 && (
//             <Pagination
//               currentPage={currentPage}
//               totalPages={totalPages}
//               pageSize={pageSize}
//               onPageChange={handlePageChange}
//               onPageSizeChange={handlePageSizeChange}
//             />
//           )}
//           {modalShow && Object.values(selectedDoctor)?.length > 0 && (
//             <BookingAppointment
//               show={modalShow}
//               handleClose={() => setModalShow(false)}
//               selectedDoctor={selectedDoctor}
//             />
//           )}

//           <MobileLogin
//             show={showLoginPopup}
//             handleClose={() => setShowLoginPopup(false)}
//           />
//           <LoginSignUp
//             showLoginSignUpModal={showLoginSignUpModal}
//             setShowLoginSignUpModal={setShowLoginSignUpModal}
//             selectedDoctor={selectedDoctor}
//           />
//         </div>
//       </section>
//       <Footer />
//       <ScrollTop />
//     </>
//   );
// }


import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import ScrollTop from "../../components/scrollTop";
import {
  FiHeart,
  FiPhone,
  RiMoneyDollarCircleLine,
  RiMapPinLine,
} from "../../assets/icons/vander";
import Pagination from "../../components/CommonComponents/Pagination";
import BookingAppointment from "../patient/booking-appointment";
import dr1 from "../../assets/images/doctors/Avatar.jpg";
import SkeletonLoader from "./SkeletonLoader";
import MobileLogin from "../../components/CommonComponents/MobileLogin";
import LoginSignUp from "../../components/CommonComponents/LoginSignUp";

export default function DoctorTeamTwo() {
  const [searchQuery, setSearchQuery] = useState("");
  const [doctors, setDoctors] = useState([]);
  const [allDoctors, setAllDoctors] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [totalPages, setTotalPages] = useState(1);

  const [modalShow, setModalShow] = useState(false);
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [showLoginSignUpModal, setShowLoginSignUpModal] = useState(false);
  const [userRole, setUserRole] = useState(null);
  const storedUserRole = localStorage.getItem("userRole");

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const searchTerm = queryParams.get("search") || "";
    setSearchQuery(searchTerm);
  }, [location.search]);

  useEffect(() => {
    if (storedUserRole) {
      setUserRole(storedUserRole);
    }
  }, [storedUserRole]);

  useEffect(() => {
    async function fetchDoctors() {
      try {
        setLoading(true);
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/getdoctorsdata/doctorslist/?page=${currentPage}&page_size=${pageSize}`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setAllDoctors(data.results);
        setDoctors(data.results);
        setTotalPages(Math.ceil(data.count / pageSize));
        setError(null);
      } catch (error) {
        console.error("Error fetching doctors:", error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    }

    fetchDoctors();
  }, [currentPage, pageSize]);

  useEffect(() => {
    if (searchQuery.trim() === "") {
      setSearchResults([]);
      setDoctors(allDoctors);
    } else {
      searchDoctors(searchQuery);
    }
  }, [searchQuery, allDoctors]);

  async function searchDoctors(query) {
    try {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/doctors/search/?search=${encodeURIComponent(query)}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setSearchResults(data);
      setDoctors(data.length ? data : []);
      setError(null);
    } catch (error) {
      console.error("Error fetching search results:", error);
      setError(error.message);
      setDoctors([]);
    } finally {
      setLoading(false);
    }
  }

  const handlePageChange = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const handlePageSizeChange = (event) => {
    setPageSize(Number(event.target.value));
    setCurrentPage(1);
  };

  const renderStars = (averageRating) => {
    const totalStars = 5;
    const rating = Math.max(0, Math.min(5, Number(averageRating) || 0));
    const filledStars = Math.floor(rating);
    const halfStar = rating - filledStars >= 0.5 ? 1 : 0;
    const emptyStars = totalStars - filledStars - halfStar;

    const starElements = [];
    starElements.push(
      ...Array(filledStars).fill(
        <i
          className="mdi mdi-star text-warning"
          key={`filled-${starElements.length}`}
        ></i>
      )
    );
    if (halfStar) {
      starElements.push(
        <i
          className="mdi mdi-star-half text-warning"
          key={`half-${starElements.length}`}
        ></i>
      );
    }
    starElements.push(
      ...Array(emptyStars).fill(
        <i
          className="mdi mdi-star-outline text-warning"
          key={`empty-${starElements.length}`}
        ></i>
      )
    );

    return <>{starElements}</>;
  };

  const handleAppointmentClick = (doctor) => {
    if (!userRole) {
      setShowLoginSignUpModal(true);
      setSelectedDoctor(doctor);
    } else {
      setSelectedDoctor(doctor);
      setModalShow(true);
    }
  };

  const handleAppointmentButtonClick = () => {
    if (!userRole) {
      setShowLoginPopup(true);
    } else {
      setSelectedDoctor(null);
      setModalShow(true);
    }
  };

  const renderExperience = (experience) => {
    if (typeof experience === "string") {
      return experience;
    } else if (typeof experience === "object" && experience !== null) {
      return Object.values(experience)[0] || "N/A";
    }
    return "N/A";
  };

  // Define responsive styles
  const cardStyle = {
    display: "flex",
    flexDirection: windowWidth < 768 ? "column" : "row",
    width: "100%",
    padding: windowWidth < 768 ? "10px" : "15px",
    marginBottom: "0",
  };

  const imageStyle = {
    width: windowWidth < 768 ? "100px" : "150px",
    height: windowWidth < 768 ? "100px" : "150px",
    borderRadius: "50%",
    objectFit: "cover",
  };

  const bodyStyle = {
    flex: 1,
    display: "flex",
    flexDirection: windowWidth < 768 ? "column" : "row",
    alignItems: "stretch",
  };

  const sideBarStyle = {
    width: windowWidth < 768 ? "100%" : "300px",
    marginLeft: windowWidth < 768 ? "0" : "15px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  };

  return (
    <>
      <Navbar
        navDark={true}
        manuClass="navigation-menu nav-left"
        containerClass="container"
      />

      <section className="section">
        <div className="container">
          <div className="search-bar mt-4">
            <input
              type="text"
              className="form-control"
              placeholder="Search doctors..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>

          <div className="row align-items-center">
            {loading ? (
              <SkeletonLoader />
            ) : error ? (
              <div className="col-12 text-center">
                <p className="text-muted">Error: {error}</p>
              </div>
            ) : doctors.length > 0 ? (
              doctors.map((doctor) => (
                <div className="col-12 p-0" key={doctor.user}>
                  <div className="card team border-0 rounded shadow overflow-hidden" style={cardStyle}>
                    <div className="team-person" style={{ flexShrink: 0 }}>
                      <img
                        src={doctor.image || dr1}
                        alt={doctor.first_name + " " + doctor.last_name}
                        style={imageStyle}
                      />
                    </div>
                    <div className="card-body" style={bodyStyle}>
                      <div style={{ flex: 1 }}>
                        <div style={{ marginBottom: "15px" }}>
                          <Link
                            to={`/doctor-profile/${doctor.id}`}
                            className="title text-dark h5 d-block mb-0"
                          >
                            {doctor.first_name} {doctor.last_name}
                          </Link>
                          <small className="text-muted speciality">
                            {doctor.specialization} <br />
                            {doctor.years_of_experience} Years Experience
                          </small>
                          <div className="d-flex align-items-center mt-2">
                            <ul className="list-unstyled mb-0 me-2">
                              {renderStars(doctor.average_rating)}
                            </ul>
                            <p className="text-muted mb-0 me-2">
                              {parseFloat(doctor.average_rating).toFixed(2).replace(/\.00$/, '')} Star
                            </p>
                          </div>
                          <ul className="list-unstyled mt-2 mb-0">
                            <li className="d-flex ms-0">
                              <RiMapPinLine className="text-primary align-middle" />
                              <small className="text-muted ms-2">
                                {doctor.location}
                              </small>
                            </li>
                            <li className="d-flex ms-0 mt-2">
                              <FiPhone className="text-primary align-middle" />
                              <small className="text-muted ms-2">
                                {doctor.contact_no}
                              </small>
                            </li>
                            <li className="d-flex ms-0 mt-2">
                              <RiMoneyDollarCircleLine className="text-primary align-middle" />
                              <small className="text-muted ms-2">
                                {doctor.charge} INR
                              </small>
                            </li>
                            <li className="d-flex ms-0 mb-2">
                              <small className="text-muted ms-2">
                                {doctor.description}
                              </small>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div style={sideBarStyle}>
                        <ul className="list-unstyled mb-3">
                          <li className="d-flex mb-2">
                            <small className="text-muted ms-2">
                              {doctor.hospital_clinic_affiliation}
                            </small>
                          </li>
                          <li className="d-flex mb-2">
                            <small className="text-muted ms-2">
                              {doctor.medical_school}
                            </small>
                          </li>
                          <li className="d-flex mb-2">
                            <small className="text-muted ms-2">
                              {doctor.street_address}
                            </small>
                          </li>
                        </ul>
                        <div className="text-end">
                          <button
                            className="btn btn-primary btn-3d"
                            onClick={() => handleAppointmentClick(doctor)}
                          >
                            Appointment
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="col-12 text-center">
                <p className="text-muted">No doctors found</p>
              </div>
            )}
          </div>

          {doctors.length > 0 && (
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              pageSize={pageSize}
              onPageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
            />
          )}
          {modalShow && Object.values(selectedDoctor)?.length > 0 && (
            <BookingAppointment
              show={modalShow}
              handleClose={() => setModalShow(false)}
              selectedDoctor={selectedDoctor}
            />
          )}

          <MobileLogin
            show={showLoginPopup}
            handleClose={() => setShowLoginPopup(false)}
          />
          <LoginSignUp
            showLoginSignUpModal={showLoginSignUpModal}
            setShowLoginSignUpModal={setShowLoginSignUpModal}
            selectedDoctor={selectedDoctor}
          />
        </div>
      </section>
      <Footer />
      <ScrollTop />
    </>
  );
}
