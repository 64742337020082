
import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import profileImage from "../../assets/images/doctors/dr-profile.png";
import Navbar from "../../components/navbar";
import AdminFooter from "../../components/dashboard/adminFooter";
import ScrollTop from "../../components/scrollTop";
import moment from "moment-timezone";
import LoginSignUp from "../../components/CommonComponents/LoginSignUp";

import {
  doctorData,
  experienceData,
  patientsData,
  partners,
} from "../../data/data";
import {
  FiHeart,
  RiMapPinLine,
  RiTimeLine,
  RiMoneyDollarCircleLine,
  FiFacebook,
  FiLinkedin,
  FiGithub,
  FiTwitter,
  FiArrowRight,
  RiTimeFill,
  FiPhone,
  FiMail,
} from "../../assets/icons/vander";
import TinySlider from "tiny-slider-react";
import "tiny-slider/dist/tiny-slider.css";
import logoIcon from "../../assets/images/logo-icon.png";
import { FaStar } from "react-icons/fa6";
import { toast, ToastContainer } from "react-toastify";
import { useAuthToken } from "../../components/AuthContext";
// import { useAuthToken } from '../../context/AuthTokenContext'; // Import the context
import dr1 from "../../assets/images/doctors/Avatar.jpg";
import BookingAppointment from "../patient/booking-appointment";
import axios from "axios";
import AddPatient from "../../components/patient/AddPatient";
import MyMapContainer from "./MyMapContainer";
export default function DoctorProfileNew() {
  let [activeIndex, setActiveIndex] = useState(1);
  const { id } = useParams();
  const [doctor, setDoctor] = useState(null);
  const [openPatientModal, setOpenPatientModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [mapUrl, setMapUrl] = useState("");
  const [liked, setLiked] = useState(false);
  const [rating, setRating] = useState(0);
  const [likes, setLikes] = useState(0);
  const [modalShow, setModalShow] = useState(false);
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [showLoginSignUpModal, setShowLoginSignUpModal] = useState(false);

  // const token = useAuthToken(); // Get the token from context or local storage
  const token = localStorage.getItem("accessToken"); // Check for the authentication token
  console.log(token);
  const [averageRating, setAverageRating] = useState(0);
  console.log(token);
  const navigate = useNavigate();
  const [userRole, setUserRole] = useState(null);
  const [patientsData, setPatientsData] = useState([]);
  const storedUserRole = localStorage.getItem("userRole");

  useEffect(() => {
    const fetchRatings = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/doctors/${id}/ratings/`
        );
        setPatientsData(response.data);
      } catch (error) {
        console.error("Error fetching ratings data:", error);
      }
    };

    fetchRatings();
  }, [id]);
  useEffect(() => {
    if (storedUserRole) {
      setUserRole(storedUserRole);
    }
  }, [storedUserRole]);
  useEffect(() => {
    async function fetchDoctor() {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/doctors/details/${id}/`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setDoctor(data);
        setLikes(data.total_likes);
        setRating(data.rating);
        setAverageRating(data.average_rating);
        const address = data.address;
        geocodeAddress(address);

        console.log(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    }

    fetchDoctor();
  }, [id]);
  useEffect(() => {});
  const geocodeAddress = async (address) => {
    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json`,
        {
          params: {
            address: address,
            key: `${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`,
          },
        }
      );
      const location = response.data.results[0].geometry.location;
      setMapUrl(
        `https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&q=${location.lat},${location.lng}`
      );
    } catch (error) {
      console.error("Error geocoding address:", error);
    }
  };

  const getStarClass = (index) => {
    if (index < Math.floor(averageRating)) {
      return "text-warning";
    }
    if (index === Math.floor(averageRating) && averageRating % 1 >= 0.5) {
      return "text-warning";
    }
    return "text-muted";
  };

  const handleLike = async () => {
    try {
      console.log("Token:", token);
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/doctors/${id}/like/`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setLikes(data.total_likes);
      setLiked(true);
    } catch (error) {
      console.error("Error liking doctor:", error);
    }
  };
  const [review, setReview] = useState("");
  const [error1, setError1] = useState("");

  const handleSubmit = async () => {
    if (rating === 0 || review.trim() === "") {
      setError1("Please rate this doctor and write a review.");
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/doctors/${id}/rate/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ rating, review_msg: review }),
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setRating(data.rating);
      setReview(""); // Clear review after submission
      setError1(""); // Clear error message on successful submission
      toast.success("Review submitted successfully!"); // Show success message
    } catch (error) {
      console.log("Error rating doctor:", error);
      // toast.error('Failed to submit review. Please try again.'); // Show error message
      toast.error(error.message);
    }
  };

  // Correct condition to disable the button
  const isSubmitDisabled = !(rating > 0 && review.trim() !== "");
  const handleButtonClick = () => {
    if (isSubmitDisabled) {
      alert("Please rate this doctor and write a review before submitting."); // Show error message
    } else {
      handleSubmit(); // Call handleSubmit if button is enabled
    }
  };


  const styles = {
    loaderOverlay: {
      position: "fixed", // Cover the entire viewport
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      background: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 1000, // Ensure it's above other content
    },
    blinkingLogo: {
      animation: "blinking 1.5s infinite", // Apply blinking animation
    },
  };

  // Add CSS for blink  ing animation and circular images
  const stylesSheet = document.createElement("style");
  stylesSheet.type = "text/css";
  stylesSheet.innerText = `
    @keyframes blinking {
         0% { opacity: 1; }
        50% { opacity: 0; }
       100% { opacity: 1; }
    }
    
    .circle-img {
        border-radius: 50%;
        width: 150px; /* Adjust as needed */
        height: 150px; /* Adjust as needed */
        object-fit: cover;
    }
    `;
  document.head.appendChild(stylesSheet);
  if (loading) {
    return (
      <div style={styles.loaderOverlay}>
        <img
          src={logoIcon}
          height="50"
          alt="Logo Icon"
          style={styles.blinkingLogo}
        />
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!doctor) {
    return <div>Doctor not found</div>;
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(date.getDate()).padStart(2, "0");
    return `${day}-${month}-${year}`;
  };
  const greetingHours = () => {
    // Get current time in IST using moment-timezone
    const now = moment().tz("Asia/Kolkata");
    const hour = now.hour();
    console.log("Hour in IST:", hour);

    // Determine greeting based on IST time
    if (hour >= 6 && hour < 12) return "Good Morning";
    if (hour >= 12 && hour < 17) return "Good Afternoon";
    if (hour >= 17 && hour < 20) return "Good Evening";
    return "Good Night";
  };

  const renderTimetable = (timetable) => {
    const formatTime = (timeStr) => {
      const [hours, minutes] = timeStr.split(":");
      return `${hours}:${minutes}`;
    };

    return (
      <tr key={timetable.id}>
        <td>{timetable.clinic_name}</td>
        <td>{timetable.clinic_address}</td>
        <td>{timetable.day_of_week}</td>
        <td>{`${formatTime(timetable.start_time)} - ${formatTime(
          timetable.end_time
        )}`}</td>
        <td>
        {`${(timetable.break_start || '').substring(0, 5)} - ${(timetable.break_end || '').substring(0, 5)}`}

          </td>
      </tr>
    );
  };
  

  // Call the function to test
  console.log(greetingHours());

  let settings = {
    container: ".slider-range-four",
    items: 4,
    controls: false,
    mouseDrag: true,
    loop: true,
    rewind: true,
    autoplay: true,
    autoplayButtonOutput: false,
    autoplayTimeout: 3000,
    navPosition: "bottom",
    speed: 400,
    gutter: 24,
    responsive: {
      992: {
        items: 4,
      },

      767: {
        items: 2,
      },

      320: {
        items: 1,
      },
    },
  };

  let settings2 = {
    container: ".client-review-slider",
    items: 1,
    controls: false,
    mouseDrag: true,
    loop: true,
    rewind: true,
    autoplay: true,
    autoplayButtonOutput: false,
    autoplayTimeout: 3000,
    navPosition: "bottom",
    speed: 400,
    gutter: 16,
  };
  if (loading) {
    return (
      <div style={styles.loaderOverlay}>
        <img
          src={logoIcon}
          height="50"
          alt="Logo Icon"
          style={styles.blinkingLogo}
        />
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!doctor) {
    return <div>Doctor not found</div>;
  }

  const renderExperience = (experience) => {
    if (Array.isArray(experience)) {
      return experience.map((item, index) => {
        const formatYear = (dateString) => {
          const date = new Date(dateString);
          // Only return the year if the date is valid
          return !isNaN(date.getTime()) ? date.getFullYear() : null;
        };
  
        const startYear = formatYear(item.start_date);
        const endYear = formatYear(item.end_date);
        const startTime = item.start_time ? item.start_time : null;
        const endTime = item.end_time ? item.end_time : null;
  
        return (
          <div className="tiny-slide text-center" key={index}>
            <div className="card border-0 p-4 item-box mb-2 shadow rounded">
              {/* Show year range only if at least one year is valid */}
              {(startYear || endYear) && (
                <p className="text-muted mb-0">
                  {startYear && endYear ? `${startYear} - ${endYear}` :
                  startYear ? `${startYear}` :
                  endYear ? `${endYear}` : ''}
                </p>
              )}
              {/* Show time range only if both startTime and endTime are available */}
              {(startTime && endTime) && (
                <p className="text-muted mb-0">
                  {`${startTime} - ${endTime}`}
                </p>
              )}
              <h6 className="mt-1">{item.job_title}</h6>
              <p className="text-muted mb-0">{item.institution}</p>
              <p className="mt-1">{item.description}</p>
            </div>
          </div>
        );
      });
    } else if (typeof experience === "string") {
      return <p>{experience}</p>;
    } else {
      return <p>No experience data available.</p>;
    }
  };
  
  // const renderExperience = (experience) => {
  //   if (Array.isArray(experience)) {
  //     return experience.map((item, index) => {
  //       const formatYear = (dateString) => {
  //         const date = new Date(dateString);
  //         return isNaN(date.getFullYear()) ? null : date.getFullYear();
  //       };
  
  //       const startYear = formatYear(item.start_date);
  //       const endYear = formatYear(item.end_date);
  
  //       return (
  //         <div className="tiny-slide text-center" key={index}>
  //           <div className="card border-0 p-4 item-box mb-2 shadow rounded">
  //             <p className="text-muted mb-0">
  //               {(startYear && endYear) ? `${startYear} - ${endYear}` :
  //                (startYear ? `${startYear}` :
  //                 (endYear ? `${endYear}` : ''))}
  //             </p>
  //             <h6 className="mt-1">{item.job_title}</h6>
  //             <p className="text-muted mb-0">{item.institution}</p>
  //             <p className="mt-1">{item.description}</p>
  //           </div>
  //         </div>
  //       );
  //     });
  //   } else if (typeof experience === "string") {
  //     return <p>{experience}</p>;
  //   } else {
  //     return <p>No experience data available.</p>;
  //   }
  // };
  
  // const renderExperience = (experience) => {
  //   if (Array.isArray(experience)) {
  //     return experience.map((item, index) => {
  //       const formatYear = (dateString) => new Date(dateString).getFullYear();

  //       return (
  //         <div className="tiny-slide text-center" key={index}>
  //           <div className="card border-0 p-4 item-box mb-2 shadow rounded">
  //             <p className="text-muted mb-0">
  //               {formatYear(item.start_date)} - {formatYear(item.end_date)}
  //             </p>
  //             <h6 className="mt-1">{item.job_title}</h6>
  //             <p className="text-muted mb-0">{item.institution}</p>
  //             <p className="mt-1">{item.description}</p>
  //           </div>
  //         </div>
  //       );
  //     });
  //   } else if (typeof experience === "string") {
  //     return <p>{experience}</p>;
  //   } else {
  //     return <p>No experience data available.</p>;
  //   }
  // };

  const handleAppointmentClick = (doctor) => {
    if (!userRole) {
      // navigate("/login")
      setShowLoginSignUpModal(true);
      setSelectedDoctor(doctor);

      // setShowLoginPopup(true);
    } else {
      setSelectedDoctor(doctor);
      setModalShow(true);
    }
  };

  return (
    <>
      <Navbar
        navDark={true}
        manuClass="navigation-menu nav-left"
        containerClass="container"
      />
      <section className="bg-dashboard my-lg-5">
        <div className="container mt-xl-5">
          <div className="row">
            <div className="col-12">
              <div className="card border-0 rounded shadow">
                <div className="row justify-content-end">
                  <div className="mt-4 d-flex justify-content-end">
                    <button
                      className="btn btn-primary btn-3d"
                      onClick={() => handleAppointmentClick(doctor)}
                      style={{ marginRight: "15px" }}
                    >
                      Appointment
                    </button>
                    {userRole === "doctor" && (
                      <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        onClick={() => setOpenPatientModal(true)}
                        style={{ marginRight: "15px" }}
                      >
                        Add Patient
                      </button>
                    )}
                    <button
                      className={`btn ${
                        liked ? "btn-danger" : "btn-outline-danger"
                      }`}
                    //   onClick={handleLike}
                    >
                      <FiHeart className="me-1" />
                      {liked ? "Unlike" : "Like"} ({likes})
                    </button>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-5 position-relative">
                    <img
                      src={doctor.image || dr1}
                      className="img-fluid dr-profile-img"
                      alt=""
                    />
                  </div>

                  <div className="col-xl-8 col-lg-8 col-md-7">
                    <div className="p-lg-5 p-4">
                      <small className="text-muted">
                        {formatDate(doctor.created_on)}
                      </small>

                      <h4 className="my-3">
                        {greetingHours()}! <br />{" "}
                        <span className="text-primary h2">{`${doctor.first_name} ${doctor.last_name}`}</span>
                      </h4>
                      <div className="d-flex align-items-center">
                        <div className="d-flex">
                          {[...Array(5)].map((_, index) => (
                            <FaStar
                              key={index}
                              className={`star ${getStarClass(index)}`}
                            />
                          ))}
                        </div>
                        <div className="ms-3 text-muted">
                          {averageRating.toFixed(1)} / 5
                        </div>
                      </div>
                      <p className="para-desc text-muted">
                        Great doctor if you need your family member to get
                        effective immediate assistance, emergency treatment or a
                        simple consultation.
                      </p>

                      {/* <h6 className="mb-0">
                        You have{" "}
                        <span className="text-primary">18 patients</span>{" "}
                        remaining today!
                      </h6> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 mt-4 pt-2">
              <div className="card border-0 shadow rounded p-4">
                <ul className="nav nav-pills nav-justified flex-column flex-sm-row rounded shadow overflow-hidden bg-light">
                  <li className="nav-item">
                    <Link
                      className={`${
                        activeIndex === 1 ? "active" : ""
                      } nav-link rounded-0`}
                      to="#"
                      onClick={() => setActiveIndex(1)}
                    >
                      <div className="text-center pt-1 pb-1">
                        <h5 className="mb-0">Overview</h5>
                      </div>
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link
                      className={`${
                        activeIndex === 2 ? "active" : ""
                      } nav-link rounded-0`}
                      to="#"
                      onClick={() => setActiveIndex(2)}
                    >
                      <div className="text-center pt-1 pb-1">
                        <h5 className="mb-0">Experience</h5>
                      </div>
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link
                      className={`${
                        activeIndex === 3 ? "active" : ""
                      } nav-link rounded-0`}
                      to="#"
                      onClick={() => setActiveIndex(3)}
                    >
                      <div className="text-center pt-1 pb-1">
                        <h5 className="mb-0">Reviews</h5>
                      </div>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={`${
                        activeIndex === 5 ? "active" : ""
                      } nav-link rounded-0`}
                      to="#"
                      onClick={() => setActiveIndex(5)}
                    >
                      <div className="text-center pt-1 pb-1">
                        <h5 className="mb-0">Time Table</h5>
                      </div>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={`${
                        activeIndex === 4 ? "active" : ""
                      } nav-link rounded-0`}
                      to="#"
                      onClick={() => setActiveIndex(4)}
                    >
                      <div className="text-center pt-1 pb-1">
                        <h5 className="mb-0">Location</h5>
                      </div>
                    </Link>
                  </li>

                  
                </ul>

                <div className="tab-content mt-4">
                  {activeIndex === 1 ? (
                    <div className="tab-pane fade show active">
                      <h5 className="mb-1">{`${doctor.first_name} ${doctor.last_name}`}</h5>
                      <Link to="#" className="text-primary">
                        {doctor.specialization}
                      </Link>
                      , &nbsp;
                      <li className="d-flex ms-0 mt-2">
                        {/* <RiMoneyDollarCircleLine className="text-primary align-middle" /> */}
                        <small className="text-muted ms-2">
                          {doctor.charge} ₹
                        </small>
                      </li>
                      <p className="text-muted mt-4">{doctor.description}</p>
                      <h6>Specialties: </h6>
                      <ul className="list-unstyled mt-4">
                        {/* Add specialties here */}
                      </ul>
                      
                    </div>
                  ) : (
                    ""
                  )}
                  
                  {activeIndex === 2 ? (
                    <div className="tab-pane fade show active">
                      <h5 className="mb-1">Experience:</h5>

                      <p className="text-muted mt-4">
                        {doctor?.expdetails}
                        
                      </p>

                      <h6>Professional Experience:</h6>

                      <div className="row">
                        <div className="col-12 mt-4">
                          <div className="col-md-12">
                            <div className="slider-range-four tiny-timeline">
                              <TinySlider settings={settings}>
                                {renderExperience(doctor.experience)}
                                
                              </TinySlider>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {activeIndex === 3 ? (
                    <div className="tab-pane fade show active">
                      <div className="row justify-content-center">
                        <div className="col-lg-8 mt-4 pt-2 text-center">
                          <div className="client-review-slider">
                            <TinySlider settings={settings2}>
                              {patientsData.map((item, index) => {
                                return (
                                  <div
                                    className="tiny-slide text-center"
                                    key={index}
                                  >
                                    <p className="text-muted fw-normal fst-italic">
                                      {item.review_msg}
                                    </p>
                                  
                                    <ul className="list-unstyled mb-0">
                                      {[...Array(item.rating)].map((_, i) => (
                                        <li
                                          className="list-inline-item"
                                          key={i}
                                        >
                                          <i className="mdi mdi-star text-warning"></i>
                                        </li>
                                      ))}
                                      {[...Array(5 - item.rating)].map(
                                        (_, i) => (
                                          <li
                                            className="list-inline-item"
                                            key={i + item.rating}
                                          >
                                            <i className="mdi mdi-star-outline text-warning"></i>
                                          </li>
                                        )
                                      )}
                                    </ul>
                                    <h6 className="text-primary">
                                      {item.user_name}
                                    </h6>
                                  </div>
                                );
                              })}

                           
                            </TinySlider>
                          </div>
                        </div>
                      </div>

                      <div className="row justify-content-center">
                        {partners.map((item, index) => {
                          return (
                            <div
                              className="col-lg-2 col-md-2 col-6 text-center py-4"
                              key={index}
                            >
                              <img
                                src={item}
                                className="avatar avatar-client"
                                alt=""
                              />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                           {activeIndex === 4 ? 
  <div className="tab-pane fade show active">
    <div className="row">
      <div className="col-12">
        <div className="card map border-0">
          <div className="card-body p-0">
            <MyMapContainer id={id} />
          </div>
        </div>
      </div>
    </div>
  </div> 
: ''}
                  {/* {activeIndex === 4 ? (
                    <div className="tab-pane fade show active">
                      <div className="row">
                        <div className="col-12">
                          <div className="card map border-0">
                            <div className="card-body p-0">
                              {mapUrl ? (
                                <iframe
                                  src={mapUrl}
                                  style={{ border: "0" }}
                                  title="doctor-location"
                                  className="rounded"
                                  allowFullScreen
                                ></iframe>
                              ) : (
                                <iframe
                                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39206.002432144705!2d-95.4973981212445!3d29.709510002925988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c16de81f3ca5%3A0xf43e0b60ae539ac9!2sGerald+D.+Hines+Waterwall+Park!5e0!3m2!1sen!2sin!4v1566305861440!5m2!1sen!2sin"
                                  style={{ border: "0" }}
                                  title="doctris"
                                  className="rounded"
                                  allowFullScreen
                                ></iframe>
                              )}
                             
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )} */}
                  {activeIndex === 5 ? (
  <div className="tab-pane fade show active">
    <div className="row">
      <div className="col-lg-8 col-md-12" style={{ overflowX: 'auto' }}>
        {doctor?.timetables && doctor?.timetables.length > 0 && (
          <div className="card border-0 p-3 rounded shadow">
            <table className="table table-bordered mb-0" style={{ whiteSpace: 'nowrap', width: '100%' }}>
              <thead>
                <tr>
                  <th>Clinic</th>
                  <th>Address</th>
                  <th>Day</th>
                  <th>Timing</th>
                  <th>Break TIme</th>
                </tr>
              </thead>
              <tbody>
                {doctor.timetables.map(renderTimetable)}
              </tbody>
            </table>
          </div>
        )}
      </div>

      <div className="col-lg-4 col-md-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
        <div className="d-flex flex-column">
          <div className="card border-0 text-center features feature-primary mb-4">
            <div className="icon text-center mx-auto rounded-md">
              <span className="mb-0">
                <FiPhone className="h3" />
              </span>
            </div>
            <div className="card-body p-0 mt-4">
              <h5 className="title fw-bold">Phone</h5>
              <Link to="#" className="link">
                {doctor.contact_no}
              </Link>
            </div>
          </div>

          <div className="card border-0 text-center features feature-primary">
            <div className="icon text-center mx-auto rounded-md">
              <span className="mb-0">
                <FiMail className="h3" />
              </span>
            </div>
            <div className="card-body p-0 mt-4">
              <h5 className="title fw-bold">Email</h5>
              <Link to="#" className="link">
                {doctor.email}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
) : (
  ""
)}

      
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <AddPatient
        openPatientModal={openPatientModal}
        setOpenPatientModal={setOpenPatientModal}
      />
      <BookingAppointment
        show={modalShow}
        handleClose={() => setModalShow(false)}
        selectedDoctor={selectedDoctor}
      />

      <LoginSignUp
        showLoginSignUpModal={showLoginSignUpModal}
        setShowLoginSignUpModal={setShowLoginSignUpModal}
        selectedDoctor={selectedDoctor}
      />
      <AdminFooter />
      <ScrollTop />
    </>
  );
}
