import React from "react";

export const loadingPanel = () => {
  return (
    <div
      className="k-loading-mask"
      style={{
        position: `fixed`,
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 9999,
      }}
    >
      <span className="k-loading-text">Loading</span>
      <div className="k-loading-image"></div>
      <div className="k-loading-color"></div>
    </div>
  );
};
export const getUserData = () => {
  const userData = localStorage.getItem("userData");
  if (userData) {
    return JSON.parse(userData);
  } else {
    return null;
  }
};

// Convert 24-hour time format to 12-hour format with AM/PM
export const convertTo12HourFormat = (timeStr) => {
  if (!timeStr) return ""; // Handle null or undefined timeStr
  const [hours, minutes] = timeStr.split(":").map(Number);
  const period = hours >= 12 ? 'PM' : 'AM';
  const adjustedHours = hours % 12 || 12;
  return `${adjustedHours}:${minutes.toString().padStart(2, '0')} ${period}`;
};

export const getMaxDate = () => {
  const today = new Date();
  const maxDate = new Date(today);
  maxDate.setDate(today.getDate() + 15); // Set max date to 15 days from today
  return maxDate;
};

